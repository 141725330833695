import { WALL_TYPE } from '@/common/WallUtils';
import { validIntegrationOrgIdList } from '@/common/FilteringUtils';

const NO_WALL_ROUTES = [ //pages we don't show general wall on
    'account-settings',
    'sso-settings',
    'mail-settings',
    'payment-settings',
    'team-members',
    'setup-guide',
    'onboarding',
    'sa-integ-callback',
    'slack-integration',
    'automation',
    'automation-rules',
    'automation-logs',
    'integration', //page doesn't use general wall template
    'attributions', //page doesn't use general wall tempalate
    'campaign-forecasting', //page doesn't use general wall tempalate
    'optimize', //page doesn't use general wall tempalate
    'budget-allocation', //page doesn't use general wall tempalate
    'spotlight', //page doesn't use general wall tempalate
    'smart-bidding', //page handles its own wall, displays wall under tabs.
    'cpa-optimization',
    'cpi-optimization',
    'cpg-optimization',
    'roas-optimization',
    'keyword-auction-insights',
    'search-by-app',
    'search-by-keyword',
    'top-advertisers',
    'keyword-gap',
];

export default {
    //heading
    computed: {
        pageVisibleWithoutIntegration() {
            return !!this.$route.meta.noIntegrationRequired;
        },
        pageRequiresMMPIntegration() {
            return !!this.$route.meta.isMMPRelated;
        },
        userHasCurrentPageAccess() {
            //routes with no feature are accessible for all users
            if (!this.$route?.meta?.feature) {
                return true;
            }
            const routeFeatures = this.$route.meta.feature;
            if (Array.isArray(routeFeatures)) {
                return routeFeatures.some(feature => this.validFeatures.includes(feature));
            }
            return this.validFeatures.includes(this.$route.meta.feature);
        },
        hasAppleIntegration() {
            return this.onboardingStatus?.sections?.integration?.state;
        },
        hasMMPIntegration() {
            const mmpSteps = this.onboardingStatus?.sections?.mmp?.steps;
            if (!mmpSteps) {
                return false;
            }
            return mmpSteps
                .find(step => step.name === 'FIRST_ATTRIBUTION')
                ?.state;
        },
        noValidOrgs() {
            const validOrgs = validIntegrationOrgIdList();
            return !validOrgs || !validOrgs.length;
        },
        wallType() {
            if (NO_WALL_ROUTES.includes(this.$route.name)) {
                return null;
            }
            //show ASA wall on every page when no search ads related product
            if (!this.isSearchAdsFreeUser && !this.isSearchAdsUser && !this.hasAppleIntegration) {
                return WALL_TYPE.INTEGRATE_ASA;
            }
            if ((!this.hasAppleIntegration && !this.pageVisibleWithoutIntegration) || this.noValidOrgs) {
                return WALL_TYPE.INTEGRATE_ASA;
            }
            if (!this.userHasCurrentPageAccess && this.isSearchAdsFreeUser) {
                return WALL_TYPE.CONTACT_SALES;
            }
            if (!this.userHasCurrentPageAccess && !this.isSearchAdsFreeUser) {
                // return WALL_TYPE.JOIN_BETA;
                return null; //join beta walls handled by the page component, until new design is ready
            }
            if (this.pageRequiresMMPIntegration && !this.hasMMPIntegration) {
                return WALL_TYPE.INTEGRATE_MMP;
            }
            return null;
        },
        listItems() {
            const routeName = this.$route.name.startsWith('dashboard-lite')
                ? 'dashboard-lite'
                : this.$route.name;
            return new Array(4).fill('').map((_, i) => {
                return `${routeName}.${i+1}`;
            });
        },
        imgFileName() {
            if (this.$route.name === 'ad-intelligence') { //can't name svg ad-intelligence, ad-blockers block it
                return 'keyword-auction-insights.svg';
            }
            if (this.$route.name.startsWith('dashboard-lite')) {
                return 'dashboard-lite.svg';
            }
            return `${this.$route.name}.svg`;
        },
        heading() {
            const routeName = this.$route.name.startsWith('dashboard-lite')
                ? 'dashboard-lite'
                : this.$route.name;
            return `${routeName}.heading`;
        },
    },
};
