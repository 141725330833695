{
    "cryptoCurrencies": {
        "BITCOIN": {
            "label": "Bitcoin",
            "code": "BTC"
        },
        "ETHEREUM": {
            "label": "Ethereum",
            "code": "ETH"
        },
        "RIPPLE":  {
            "label": "XRP",
            "code": "XRP"
        },
        "TETHER": {
            "label": "Tether",
            "code": "USDT"
        },
        "DOGECOIN":  {
            "label": "Dogecoin",
            "code": "DOGE"
        },
        "BONK": {
            "label": "Bonk",
            "code": "BONK"
        },
        "SOLANA":  {
            "label": "Solana",
            "code": "SOL"
        },
        "LITECOIN": {
            "label": "Litecoin",
            "code": "LTC"
        },
        "PEPE":  {
            "label": "Pepe",
            "code": "PEPE"
        },
        "CARDANO": {
            "label": "Cardano",
            "code": "ADA"
        },
        "WORLDCOIN":  {
            "label": "Worldcoin",
            "code": "WLD"
        },
        "TRON": {
            "label": "TRON",
            "code": "TRX"
        },
        "IOTA":  {
            "label": "IOTA",
            "code": "IOTA"
        }
    },
    "conditions": {
        "SPEND": "@:headers.spend.title",
        "CPA": "@:headers.averageCPA.title",
        "TOTAL_CPA": "@:headers.totalCpa.title",
        "CPT": "@:headers.averageCPT.title",
        "TTR": "@:headers.ttr.title",
        "CR": "@:headers.conversionRate.title",
        "TOTAL_CR": "@:headers.totalCr.title",
        "INSTALLS": "@:headers.installs.title",
        "VIEW_INSTALLS": "@:headers.viewInstalls.title",
        "TOTAL_INSTALLS": "@:headers.totalInstalls.title",
        "TAPS": "@:headers.taps.title",
        "IMPRESSIONS": "@:headers.impressions.title",
        "TOTAL_BUDGET": "@:headers.totalBudget.title",
        "DAILY_BUDGET": "@:headers.dailyBudget.title",
        "NEW_DOWNLOADS": "@:headers.newDownloads.title",
        "VIEW_NEW_DOWNLOADS": "@:headers.viewNewDownloads.title",
        "TOTAL_NEW_DOWNLOADS": "@:headers.totalNewDownloads.title",
        "REDOWNLOADS": "@:headers.redownloads.title",
        "VIEW_REDOWNLOADS": "@:headers.viewRedownloads.title",
        "TOTAL_REDOWNLOADS": "@:headers.totalRedownloads.title",
        "ATTR_INSTALLS": "@:headers.attrInstalls.title",
        "GOALS": "@:headers.numberOfGoals.title",
        "GOALS_REVENUE": "@:headers.goalsRevenue.title",
        "INSTALL_RATE": "@:headers.installRate.title",
        "CPI": "@:headers.averageCPI.title",
        "COST_PER_GOAL": "@:headers.costPerGoal.title",
        "GOALS_RATE": "@:headers.goalsRate.title",
        "REVENUE_PER_GOAL": "@:headers.revenuePerGoal.title",
        "GOALS_ROAS": "@:headers.goalsROAS.title",
        "ARPU": "@:headers.averageRevenuePerUser.title",
        "GOALS_REVENUE_1": "@:headers.goalRevenue1.title",
        "GOALS_REVENUE_3": "@:headers.goalRevenue3.title",
        "GOALS_REVENUE_7": "@:headers.goalRevenue7.title",
        "GOALS_REVENUE_14": "@:headers.goalRevenue14.title",
        "GOALS_REVENUE_30": "@:headers.goalRevenue30.title",
        "GOALS_REVENUE_60": "@:headers.goalRevenue60.title",
        "GOALS_COUNT_1": "@:headers.goalCount1.title",
        "GOALS_COUNT_3": "@:headers.goalCount3.title",
        "GOALS_COUNT_7": "@:headers.goalCount7.title",
        "GOALS_COUNT_14": "@:headers.goalCount14.title",
        "GOALS_COUNT_30": "@:headers.goalCount30.title",
        "GOALS_COUNT_60": "@:headers.goalCount60.title",
        "GOALS_ROAS_1": "@:headers.goalRoas1.title",
        "GOALS_ROAS_3": "@:headers.goalRoas3.title",
        "GOALS_ROAS_7": "@:headers.goalRoas7.title",
        "GOALS_ROAS_14": "@:headers.goalRoas14.title",
        "GOALS_ROAS_30": "@:headers.goalRoas30.title",
        "GOALS_ROAS_60": "@:headers.goalRoas60.title",
        "CUSTOM_COLUMN": "@:headers.CUSTOM_COLUMN.title",
        "LABEL": "@:headers.label.title",
        "APPLE_SHARE_OF_VOICE_DAILY": "@:headers.appleShareOfVoiceDaily.title",
        "APPLE_SHARE_OF_VOICE_WEEKLY": "@:headers.appleShareOfVoiceWeekly.title",
        "CPA_GOAL": "@:headers.cpaGoal.title",
        "LTV": "@:headers.ltv.title",
        "BID_AMOUNT": "@:headers.bidAmount.title",
        "DEFAULT_BID_AMOUNT": "@:headers.defaultBidAmount.title",
        "ORGANIC_RANK": "@:headers.organicRank.title",
        "POPULARITY": "@:headers.popularityRank.title",
        "STOREFRONT": "@:headers.storefront.title",
        "CAMPAIGN_NAME": "@:headers.campaign.title",
        "CAMPAIGN_GROUP_NAME": "@:headers.orgName.title",
        "AD_GROUP_NAME": "@:headers.adGroupName.title",
        "SEARCH_MATCH": "@:headers.searchMatch.title",
        "DEVICE": "@:headers.deviceClass.title",
        "GENDER": "@:headers.gender.title",
        "CUSTOMER_TYPE": "@:headers.customerType.title",
        "LOCATION": "@:headers.locality.title",
        "AGE_RANGE": "@:headers.age.title",
        "KEYWORD": "@:headers.keyword.title",
        "MATCH_TYPE": "@:headers.matchType.title",
        "SUGGESTED_BID_AMOUNT": "@:headers.suggestedBidAmount.title",
        "BID_STRENGTH": "@:headers.bidStrength.title",
        "ADDED_AS_KEYWORD": "@:headers.addedAsKeyword.title",
        "SEARCH_TERM_SOURCE": "@:headers.searchTermSource.title",
        "SEARCH_TERM_TEXT": "@:headers.searchTermText.title",
        "LOW_VOLUME_TERM": "@:headers.lowVolumeKeyword.title",
        "LAST_BUDGET_UPDATE": "@:headers.lastBudgetUpdate.title",
        "LAST_BID_UPDATE": "@:headers.lastBidUpdate.title"
    },
    "headers": {
        "GROUP_BY_CAMPAIGN_TYPE": {
            "title": "Campaign Type",
            "tooltip": "The way you structure the campaigns in your campaign group can impact performance, reporting, and your ability to scale. To set campaigns up for success, we recommend that you separate them by keyword theme or strategy. To start, it’s a good idea to create the following four campaign types:<br><strong>Brand campaign</strong> focused on customers searching for your app or company name.<br><strong>Generic campaign</strong> focused on customers searching for non-branded terms that describe your app’s category and what your app does.<br><strong>Competitor campaign</strong> focused on customers searching for apps similar to yours, within the same or a related app category.<br><strong>Discovery campaign</strong> focused on finding new search terms relevant to your app."
        },
        "actionFrequency": {
            "title": "Action Frequency",
            "tooltip": "The minimum amount of time until the</br>same action can be taken on an object by</br>the rule."
        },
        "actions": {
            "title": "Actions",
            "tooltip": "Actions"
        },
        "addedAsKeyword": {
            "title": "Added as Keyword"
        },
        "adChannelType": {
            "title": "Ad Channel"
        },
        "adGroupId": {
            "title": "Ad Group ID",
            "tooltip": "Apple Search Ads Identifier for Ad Group."
        },
        "adGroup": {
            "title": "Ad Group",
            "tooltip": "Ad Group"
        },
        "adGroupName": {
            "title": "Ad Group Name",
            "tooltip": "Ad Group Name"
        },
        "adGroupDisplayStatus": {
            "title": "Ad Group Status",
            "tooltip": "Ad Group Status"
        },
        "adGroupLabel": {
            "title": "Ad Group Label",
            "tooltip": "Labels described by user."
        },
        "adGroupStatus": {
            "title": "Ad Group Status",
            "tooltip": "Ad Group Status"
        },
        "adId": {
            "title": "Ad ID",
            "tooltip": "Apple Search Ads Identifier for Ad."
        },
        "adName": {
            "title": "Ad Name",
            "tooltip": "Ad Name"
        },
        "adPlacement": {
            "title": "Ad Placement",
            "tooltip": "Ad Placement"
        },
        "adStatus": {
            "title": "Ad Status",
            "tooltip": "Ad Status"
        },
        "age": {
            "title": "Age Range",
            "tooltip": "Age Range"
        },
        "ageRange": {
            "title": "Age Range",
            "tooltip": "Age Range"
        },
        "allConversionsRate": {
            "title": "All Conversions Rate",
            "tooltip": "The rate of total conversion to the total installs you have generated."
        },
        "app": {
            "title": "App",
            "tooltip": "Application"
        },
        "application": {
            "title": "Application"
        },
        "apps": {
            "title": "Apps"
        },
        "appId": {
            "title": "App ID",
            "tooltip": "Apple Search Ads Identifier for Application."
        },
        "shareOfVoice": {
            "title": "Apple Share of Voice",
            "tooltip": "Share of Voice (SOV) represents the proportion of total impressions your ad(s) captured for the same keywords in the same countries and regions. Data reflects Apple's SOV from the previous week."
        },
        "topAppsByAppleSOV": {
            "title": "Top Apps by Apple SOV",
            "tooltip": "Shows which apps dominate the Share of Voice (SOV) for any given keyword in a storefront. Last week’s average Apple SOV data is used, or MobileAction’s estimates when Apple data is not available."
        },
        "appleShareOfVoiceDaily": {
            "title": "Apple Share of Voice (Daily)"
        },
        "appleShareOfVoiceWeekly": {
            "title": "Apple Share of Voice (Weekly)"
        },
        "appName": {
            "title": "Application",
            "tooltip": "Application"
        },
        "applicationName": {
            "title": "Application Name",
            "tooltip": "Application Name"
        },
        "appShare": {
            "title": "Imp. Share",
            "tooltip": "Indicates the percentage of impressions the current app is getting for the keyword."
        },
        "appliedData": {
            "title": "Applied Data",
            "tooltip": "The data set that the automation rule was set for."
        },
        "arpu": {
            "title": "@:headers.averageRevenuePerUser.title",
            "tooltip": "@:headers.averageRevenuePerUser.tooltip"
        },
        "assets": {
            "title": "Assets",
            "tooltip": "The assets are shown according to the app’s primary language and iPhone 6.5'' Display."
        },
        "attrInstallRate": {
            "title": "@:headers.installRate.title",
            "tooltip": "@:headers.installRate.tooltip"
        },
        "attrInstalls": {
            "title": "Attr Installs",
            "tooltip": "The number of times a user downloads an app and opens it for the first time.<br> A N/A figure means that you should integrate SearchAds.com with an attribution tool"
        },
        "automationActions": {
            "title": "Actions",
            "tooltip": "The actions that the automation rule will take."
        },
        "automationConditions": {
            "title": "Conditions",
            "tooltip": "The pre-prepared conditions of the automation rule."
        },
        "automationCreatedAt": {
            "title": "Created At",
            "tooltip": "The creation date of the automation rule."
        },
        "automationName": {
            "title": "Name",
            "tooltip": "Name of the automation rule."
        },
        "automationState": {
            "title": "State",
            "tooltip": "The state of the automation rule."
        },
        "automationUpdatedAt": {
            "title": "Updated At",
            "tooltip": "The update date of the automation rule."
        },
        "averageCPA": {
            "shortTitle": "Avg CPA",
            "title": "Average CPA (Tap-through)",
            "tooltip": "Total campaign spend divided by the number of tap-through installs within the reporting period."
        },
        "averageCPI": {
            "title": "CPI",
            "tooltip": "<b>Average CPI(Cost per Install):</b> <br>The total number of ad spent divided by the total number of installs. <br>A N/A figure means that you should integrate SearchAds.com with an attribution tool."
        },
        "averageCPT": {
            "shortTitle": "Avg CPT",
            "title": "Average CPT",
            "tooltip": "<b>Average cost per tap (CPT)</b> <br>The average you have spent for taps. <br>This is calculated by dividing the total spent to the number of taps."
        },
        "averageRevenuePerUser": {
            "title": "ARPU",
            "tooltip": "<b>ARPU(Average Revenue Per User):</b> <br>Displays the Average Revenue Per User. <br>The total sum of revenue divided by the total number of installs."
        },
        "bidAmount": {
            "title": "Bid Amount"
        },
        "budgetChangeSource": {
            "title": "Source",
            "tooltip": "This column shows the source of the campaign's daily budget change. The are four source types:<br><ul><li><b>Dashboard:</b> The campaign’s daily budget is updated from the Ads Manager dashboard.</li><li><b>External:</b> The campaign’s daily budget is updated from Apple Native.</li><li><b>Automation:</b> The campaign’s daily budget is updated using an Automation rule.</li><li><b>Budget Allocation:</b> The campaign’s daily budget is updated using Budget Allocation.</li><li><b>Quick Automation:</b> The campaign’s daily budget is updated using Optimize.</li></ul>"
        },
        "defaultBidAmount": {
            "title": "Default Bid Amount"
        },
        "suggestedBidAmount": {
            "title": "Suggested Bid Amount"
        },
        "bidStrength": {
            "title": "Bid Strength",
            "tooltip": "<b>Weak bid strength (red)</b> means your bid isn’t competitive in relation to other advertisers’ bids. Your ad has a relatively low likelihood of being shown.<br> <b>Moderate bid strength (yellow)</b> indicates an opportunity to improve your bid’s competitiveness and increase the likelihood of your ad being shown.<br><b>Strong bid strength (green)</b> means your bid is well positioned among other advertisers in the marketplace.<br><b>No indicator</b> will be shown when there is insufficient data for us to suggest a bid range or measure bid strength, the keyword has been paused, the ad group or campaign is on hold, or your current bid is already aligned with our recommendation."
        },
        "campaign": {
            "title": "Campaign",
            "tooltip": "The name of the integrated campaigns"
        },
        "campaignDailyBudget": {
            "title": "Daily Budget",
            "tooltip": "The total that can be spent on a day. This will reset daily provided that your lifetime budget isn’t full."
        },
        "campaignId": {
            "title": "Campaign ID",
            "tooltip": "Apple Search Ads Identifier for Campaign."
        },
        "campaignLabel": {
            "title": "Campaign Label",
            "tooltip": "Labels described by user."
        },
        "campaignStatus": {
            "title": "Campaign Status",
            "tooltip": "Campaign Status"
        },
        "campaignTotalBudget": {
            "title": "Lifetime Budget",
            "tooltip": "The lifetime budget allocated to a campaign."
        },
        "categoryName": {
            "title": "Category Name",
            "tooltip": "The categories to which the apps belong to"
        },
        "categoryRank": {
            "title": "Category Rank",
            "tooltip": "This shows ranking graph of selected app on different Categories."
        },
        "categoryRanking": {
            "title": "Category Ranking",
            "tooltip": "Category Ranking"
        },
        "chance": {
            "title": "Chance",
            "tooltip": "The Chance Score shows the probability of any app<br>to rank in the top 10 results for a specific keyword.<br>The Chance score is scaled from 0 to 100,<br>with 100 being the easiest keyword to rank in the Top 10<br>search results."
        },
        "competitors": {
            "title": "Competitors",
            "tooltip": "Add your competitors to get recommendations and insights about them"
        },
        "conditions": {
            "title": "Conditions",
            "tooltip": "The conditions that will define the automation rule."
        },
        "conversionRate": {
            "title": "Conversion Rate (Tap-through)",
            "tooltip": "Total number of tap-through installs divided by the total number of taps within the reporting period."
        },
        "costPerConversion": {
            "title": "Cost Per Conversion",
            "tooltip": "<b>Cost Per Conversion</b> <br>The average amount you have spent for each conversion. <br>This is calculated by dividing your total spend to your total conversions."
        },
        "costPerGoal": {
            "title": "Cost Per Goal",
            "tooltip": "The average you have spent to achieve a goal."
        },
        "country": {
            "title": "Storefront"
        },
        "cpaGoal": {
            "title": "CPA Cap",
            "tooltip": "<p>CPA Cap</p>\nThis optional setting specifies the amount you’re willing to spend for conversion and will set a bid ceiling for all keywords in your ad group.<br>\nIt’s calculated as follows: CPA Cap x Conversion Rate<br>\nIf the keyword max CPT bid is lower than the result of the above equation, it’ll be applied instead.<br>\nSetting a CPA cap is completely optional and limits impressions and conversions.<br>"
        },
        "cpi": {
            "title": "@:headers.averageCPI.title",
            "tooltip": "@:headers.averageCPI.tooltip"
        },
        "cr": {
            "title": "Conversion Rate (Tap-through)",
            "tooltip": "Total number of tap-through installs divided by the total number of taps within the reporting period."
        },
        "creativeSetName": {
            "title": "Creative Set",
            "tooltip": "Creative Set"
        },
        "creativeSetStatus": {
            "title": "Creative Set Status",
            "tooltip": "Creative Set Status"
        },
        "creativeType": {
            "title": "Creative Type",
            "tooltip": "Creative Type"
        },
        "csvReport": {
            "title": "CSV Report",
            "tooltip": "CSV Report with the detailed information about the applied rule.<br><br>Report are not created for the rules that have run without changes."
        },
        "currency": {
            "title": "Currency"
        },
        "currentBid": {
            "title": "Current Bid",
            "tooltip": "Current Bid"
        },
        "currentDailyBudget": {
            "title": "Current Daily Budget",
            "tooltip": "Current Daily Budget"
        },
        "currentLifetimeBudget": {
            "title": "Current Lifetime Budget",
            "tooltip": "Current Lifetime Budget"
        },
        "customColumn": {
            "new": "New Custom Column",
            "title": "Custom Column"
        },
        "customerType": {
            "title": "Customer Type",
            "tooltip": "You can refine your audience at the ad group level by these customer types:<br><br>All users <br>New users<br>Returning users<br>Users of my other apps"
        },
        "dailyBudget": {
            "title": "Daily Budget",
            "tooltip": "<b>Daily budget</b> is the average amount you want to spend on your campaign each day over the course of a month. Your monthly spend won’t be more than your daily budget times the average number of days in a calendar month. On days with opportunities to get more downloads, your spend may exceed your daily budget.<br><br><br>Your campaign will continue to spend every month based on your daily budget amount, unless you pause it, remove it, or set an end date. If you set an end date, you won’t spend more than the total number of days in the campaign times your daily budget.<br><br><br>You can adjust your daily budget at any time."
        },
        "dayPart": {
            "title": "Dayparting",
            "tooltip": "Dayparting"
        },
        "defaultAmount": {
            "title": "@:headers.defaultMaxCPTBid.title",
            "tooltip": "@:headers.defaultMaxCPTBid.tooltip"
        },
        "defaultCPTAmount": {
            "title": "Default CPT Bid",
            "tooltip": "<b>Default Max CPT Bid</b> <br>This is the maximum amount you’re willing to pay<br>for a tap on your ad. This bid will apply to all keywords<br>in this ad group unless you apply an individual keyword bid."
        },
        "defaultMaxCPMBid": {
            "title": "Default CPM Bid",
            "tooltip": "<b>Default Max CPM Bid</b> <br>This is the maximum amount you’re willing to pay<br>for a thousand impressions on your ad."
        },
        "defaultMaxCPTBid": {
            "title": "Default CPT Bid",
            "tooltip": "<b>Default Max CPT Bid</b> <br>This is the maximum amount you’re willing to pay<br>for a tap on your ad. This bid will apply to all keywords<br>in this ad group unless you apply an individual keyword bid."
        },
        "deviceClass": {
            "title": "Device",
            "tooltip": "Device Class"
        },
        "difficultyRank": {
            "title": "Difficulty Score",
            "tooltip": "Difficulty is a metric that measures how difficult it is for an app to be organically top ranking for the keyword. Read more on <a href=\"https://help.mobileaction.co/en/articles/4201403-what-is-difficulty-score\" target=\"_blank\" style=\"color: #1c8de0\">Difficulty Score</a>."
        },
        "dimensions": {
            "title": "Dimensions",
            "tooltip": "A dimension is an element of your Search Ads campaign that can be included in a custom report. For example, campaign ID or CPT bid. Dimensions appear as rows in your custom reports."
        },
        "adDisplayStatus": {
            "title": "Ad Display Status",
            "tooltip": "This is the read only status shown in native platform."
        },
        "displayStatus": {
            "title": "Status",
            "tooltip": "Status"
        },
        "downloadPercentage": {
            "title": "% of Organic Downloads",
            "tooltip": "The top keywords that are providing the majority of your organic downloads."
        },
        "endDate": {
            "title": "End Date",
            "tooltip": "End Date"
        },
        "engagements": {
            "title": "Engagements",
            "tooltip": "The number of events that comes with Engagement goal category."
        },
        "frequency": {
            "title": "Check Frequency",
            "tooltip": "The frequency in which the automation rule that has been set will be checked to see if it has met the conditions or not."
        },
        "gameEvents": {
            "title": "Game Events",
            "tooltip": "The number of events that comes with Game Event goal category."
        },
        "gender": {
            "title": "Gender",
            "tooltip": "Gender"
        },
        "goalCount1": {
            "title": "Goals Count 1",
            "tooltip": "The total number of goals occurred in 1 day for your app."
        },
        "goalCount14": {
            "title": "Goals Count 14",
            "tooltip": "The total number of goals occurred in 14 days for your app."
        },
        "goalCount3": {
            "title": "Goals Count 3",
            "tooltip": "The total number of goals occurred in 3 days for your app."
        },
        "goalCount30": {
            "title": "Goals Count 30",
            "tooltip": "The total number of goals occurred in 30 days for your app."
        },
        "goalCount60": {
            "title": "Goals Count 60",
            "tooltip": "The total number of goals occurred in 60 days for your app."
        },
        "goalCount7": {
            "title": "Goals Count 7",
            "tooltip": "The total number of goals occurred in 7 days for your app."
        },
        "goalRevenue": {
            "title": "@:headers.goalsRevenue.title",
            "tooltip": "@:headers.goalsRevenue.tooltip"
        },
        "goalRevenue1": {
            "title": "Goals Revenue 1",
            "tooltip": "The total revenue your ad campaigns have generated in 1 day for your app."
        },
        "goalRevenue14": {
            "title": "Goals Revenue 14",
            "tooltip": "The total revenue your ad campaigns have generated in 14 days for your app."
        },
        "goalRevenue3": {
            "title": "Goals Revenue 3",
            "tooltip": "The total revenue your ad campaigns have generated in 3 days for your app."
        },
        "goalRevenue30": {
            "title": "Goals Revenue 30",
            "tooltip": "The total revenue your ad campaigns have generated in 30 days for your app."
        },
        "goalRevenue60": {
            "title": "Goals Revenue 60",
            "tooltip": "The total revenue your ad campaigns have generated in 60 days for your app."
        },
        "goalRevenue7": {
            "title": "Goals Revenue 7",
            "tooltip": "The total revenue your ad campaigns have generated in 7 days for your app."
        },
        "goalRoas": {
            "title": "@:headers.goalsROAS.title",
            "tooltip": "@:headers.goalsROAS.tooltip"
        },
        "goalRoas1": {
            "title": "Goals ROAS 1",
            "tooltip": "The rate of revenue generated from your total ad spend for 1 day. <br>This metric considers the revenue from each identified goal under custom conversions.<br><b>Goals Revenue 1/Spend * 100%</b>."
        },
        "goalRoas14": {
            "title": "Goals ROAS 14",
            "tooltip": "The rate of revenue generated from your total ad spend for 14 days. <br>This metric considers the revenue from each identified goal under custom conversions.<br><b>Goals Revenue 14/Spend * 100%</b>."
        },
        "goalRoas3": {
            "title": "Goals ROAS 3",
            "tooltip": "The rate of revenue generated from your total ad spend for 3 days. <br>This metric considers the revenue from each identified goal under custom conversions.<br><b>Goals Revenue 3/Spend * 100%</b>."
        },
        "goalRoas30": {
            "title": "Goals ROAS 30",
            "tooltip": "The rate of revenue generated from your total ad spend for 30 days. <br>This metric considers the revenue from each identified goal under custom conversions.<br><b>Goals Revenue 30/Spend * 100%</b>."
        },
        "goalRoas60": {
            "title": "Goals ROAS 60",
            "tooltip": "The rate of revenue generated from your total ad spend for 60 days. <br>This metric considers the revenue from each identified goal under custom conversions.<br><b>Goals Revenue 60/Spend * 100%</b>."
        },
        "goalRoas7": {
            "title": "Goals ROAS 7",
            "tooltip": "The rate of revenue generated from your total ad spend for 7 days. <br>This metric considers the revenue from each identified goal under custom conversions.<br><b>Goals Revenue 7/Spend * 100%</b>."
        },
        "goalsROAS": {
            "title": "Goals ROAS",
            "tooltip": "The rate of revenue generated from your total ad spend. <br>This metric considers the revenue from each identified goal under custom conversions.<br><b>Goals Revenue/Spend * 100%</b>."
        },
        "goalsRate": {
            "title": "Goals Rate",
            "tooltip": "The total conversion actions you’ve included into Goals<br>and accomplished by users divided by the total number of<br>installs from the mobile tracker within a period.<br><b>Goals Rate = Goals / Installs * 100%</b>"
        },
        "goalsRevenue": {
            "title": "Goals Revenue",
            "tooltip": "The total sum of conversion values (monetary values<br>associated with each in-app event included into conversions)<br>from your conversions that are included into “Goals”."
        },
        "goalsRoasDays": {
            "title": "Goals ROAS 1/3/7/14/30 Days",
            "tooltip": "The rate of revenue generated from your total ad spend for 1/3/7/14/30 day. <br>This metric considers the revenue from each identified goal under custom conversions.<br><b>Goals Revenue Days/Spend * 100%</b>."
        },
        "goalValue": {
            "title": "Goal Value",
            "tooltip": "It should be an actual or representative value which will be used to calculate the revenue"
        },

        "impressions": {
            "title": "Impressions",
            "tooltip": "The number of times you have appeared as an ad."
        },
        "installRate": {
            "title": "Attr Install Rate",
            "tooltip": "The rate of total attr installs compared to the total installs. <br> <b>Attr Installs / Installs(New Downloads+ ReDownloads) * 100</b>"
        },
        "installs": {
            "title": "Installs (Tap-through)",
            "tooltip": "Total number of new downloads and redownloads from users who tapped on your ad within a 30-day attribution window."
        },
        "keyword": {
            "title": "Keyword",
            "tooltip": "The keywords that this app is bidding on"
        },
        "negativeKeyword": {
            "title": "Negative Keyword",
            "tooltip": "Negative Keyword"
        },
        "keywordDisplayStatus": {
            "title": "Keyword Status",
            "tooltip": "Keyword Status"
        },
        "keywordLabel": {
            "title": "Keyword Label",
            "tooltip": "Labels described by user."
        },
        "keywordMatchType": {
            "title": "@:headers.matchType.title",
        },
        "keywordStatus": {
            "title": "Keyword Status"
        },
        "label": {
            "title": "Label",
            "tooltip": "Labels described by user."
        },
        "lastBudgetUpdate": {
            "title": "Last Daily Budget Update",
            "tooltip": "The date of the last daily budget update."
        },
        "lastBidUpdate": {
            "title": "Last Bid Update",
            "tooltip": "The date of the last bid update."
        },
        "localSpend": {
            "title": "Local Spend"
        },
        "locality": {
            "title": "Location",
            "tooltip": "Location"
        },
        "lowVolume": {
            "title": "Low Volume",
            "tooltip": "Note that 'Low volume' indicates that the data is below Apple’s privacy threshold. Search terms must reach at least 10 impressions, and age, gender or location reports require at least 100 impressions before search terms specific data can be shown. The low volume indicator shows cumulative report values for all terms below these thresholds."
        },
        "lowVolumeKeyword": {
            "title": "Low Volume Term",
            "tooltip": "Low Volume Term"
        },
        "ltv": {
            "title": "LTV",
            "tooltip": "Displays the Life Time Value of the Campaign"
        },
        "matchSource": {
            "title": "Match Source",
            "tooltip": "The match source column tells you whether your impression was the result of Search Match or a bidded keyword."
        },
        "matchType": {
            "title": "Match Type"
        },
        "message": {
            "title": "Message"
        },
        "newDownloads": {
            "title": "New Downloads (Tap-through)",
            "tooltip": "New downloads from users who tapped on your ad and have not previously downloaded your app. These are counted within a 30-day attribution window."
        },
        "notification": {
            "title": "Notification"
        },
        "numberOfAds": {
            "title": "Number of ads",
            "tooltip": "The number of ads this Product Page is used with."
        },
        "numberOfApps": {
            "title": "Total Apps",
            "tooltip": "The total number of apps that are ranking for each keyword."
        },
        "numberOfGoal": {
            "title": "@:headers.numberOfGoals.title",
            "tooltip": "@:headers.numberOfGoals.tooltip"
        },
        "numberOfGoals": {
            "title": "Goals",
            "tooltip": "A custom in-app event set by the user during the creation of a custom conversion. <br>The Goal is the most important action you have set up for a custom conversion."
        },
        "numberOfInstall": {
            "title": "@:headers.attrInstalls.title",
            "tooltip": "@:headers.attrInstalls.tooltip"
        },
        "optimization": {
            "title": "Strategy"
        },
        "orgName": {
            "title": "Campaign Group",
            "tooltip": "Campaign Group Name"
        },
        "campaignGroupName": {
            "title": "Campaign Group Name",
            "tooltip": "Campaign Group Name"
        },
        "organicRank": {
            "title": "Organic Rank",
            "tooltip": "This ranking data shows App’s organic rank on this keyword when searched in the Appstore."
        },
        "otherEvents": {
            "title": "Others",
            "tooltip": "The number of events that comes with Other goal category."
        },
        "others": {
            "title": "Others",
            "tooltip": "Other competing apps that also are getting impression on the keyword"
        },
        "overallRanking": {
            "title": "Overall Ranking",
            "tooltip": "Overall Ranking"
        },
        "policy": {
            "title": "Policy",
            "tooltip": "Choose how you want to track the value for the events you monitor with this custom goal. The value will be used in calculating the revenue"
        },
        "popularityRank": {
            "title": "Popularity",
            "tooltip": "This score is provided by Apple SearchAds to show how popular an keyword in the storefront."
        },
        "productPageName": {
            "title": "Product Page Name",
            "tooltip": "Product Page Name"
        },
        "productPageId": {
            "title": "Product Page Id",
            "tooltip": "Product Page Id"
        },
        "purchases": {
            "title": "Purchases",
            "tooltip": "The number of events that comes with Purchase goal category."
        },
        "keywordId": {
            "title": "Keyword ID",
            "tooltip": "Apple Search Ads Identifier for Keyword."
        },
        "rank": {
            "title": "Rank",
            "tooltip": "Selected app’s ranking for each keyword."
        },
        "rankVsRank": {
            "title": "Rank Vs Rank",
            "tooltip": "Selected app’s ranking for each keyword."
        },
        "reAttrType": {
            "title": "Re-Attribution Type",
            "tooltip": "This column shows re-attribution data usage for rules’ calculations."
        },
        "recommendedAction": {
            "title": "Recommended Action",
            "tooltip": "Recommended because of the keywords have a significantly lower conversion rate"
        },
        "recommendedActions": {
            "title": "Actions",
            "tooltip": "You can apply or dismiss our recommendations individually. Using recommendations doesn't guarantee improved performance as market prices, consumer preferences, and other factors are dynamic"
        },
        "recommendedBid": {
            "title": "Recommended Bid",
            "tooltip": "Recommended because of the keywords have insufficient impressions to compete your competitors"
        },
        "recommendedDailyBudget": {
            "title": "Recommended Daily Budget",
            "tooltip": "Recommended because of the campaigns have a significantly higher conversion rate and hits their daily budget couple of times"
        },
        "recommendedLifetimeBudget": {
            "title": "Recommended Lifetime Budget",
            "tooltip": "Recommended Lifetime Budget"
        },
        "redownloads": {
            "title": "Redownloads (Tap-through)",
            "tooltip": "Redownloads from users who tapped on your ad within a 30-day attribution window.These are counted when a user downloads your app, deletes it, and downloads it again on the same device or a different one following an ad tap."
        },
        "reportDate": {
            "title": "Report Date",
            "tooltip": "Apple Search Ads reporting date."
        },
        "returnOnAdvertisingSpend": {
            "title": "ROAS",
            "tooltip": "<b>ROAS(Return on Advertising Spend):</b> <br>Displays the Return On Advertisement Spent. <br>ROAS is the amount of revenue you have made per each unit you have spent on ads. <br>This is calculated by the following formula: <br><b>ROAS=Revenue/Spend * 100%</b>."
        },
        "revenue": {
            "title": "Revenue",
            "tooltip": "The total revenue your ad campaigns have generated for your app."
        },
        "revenuePerConversion": {
            "title": "Revenue Per Conversion",
            "tooltip": "The average revenue you have generated from each conversion."
        },
        "revenuePerGoal": {
            "title": "Revenue Per Goal",
            "tooltip": "Indicates the average revenue you generate with each successful goal. <br>This is calculated by dividing the total number of successful goals to<br>the Goals Revenue number."
        },
        "roas1": {
            "title": "ROAS 1",
            "tooltip": "<b>ROAS(Return on Advertising Spend):</b> <br>Displays the Return On Advertisement Spent for 1 day. <br>ROAS is the amount of revenue you have made per each unit you have spent on ads. <br>This is calculated by the following formula: <br><b>ROAS=Revenue 1/Spend * 100%</b>."
        },
        "roas14": {
            "title": "ROAS 14",
            "tooltip": "<b>ROAS(Return on Advertising Spend):</b> <br>Displays the Return On Advertisement Spent for 14 days. <br>ROAS is the amount of revenue you have made per each unit you have spent on ads. <br>This is calculated by the following formula: <br><b>ROAS=Revenue 14/Spend * 100%</b>."
        },
        "roas3": {
            "title": "ROAS 3",
            "tooltip": "<b>ROAS(Return on Advertising Spend):</b> <br>Displays the Return On Advertisement Spent for 3 days. <br>ROAS is the amount of revenue you have made per each unit you have spent on ads. <br>This is calculated by the following formula: <br><b>ROAS=Revenue 3/Spend * 100%</b>."
        },
        "roas30": {
            "title": "ROAS 30",
            "tooltip": "<b>ROAS(Return on Advertising Spend):</b> <br>Displays the Return On Advertisement Spent for 30 days. <br>ROAS is the amount of revenue you have made per each unit you have spent on ads. <br>This is calculated by the following formula: <br><b>ROAS=Revenue 30/Spend * 100%</b>."
        },
        "roas7": {
            "title": "ROAS 7",
            "tooltip": "<b>ROAS(Return on Advertising Spend):</b> <br>Displays the Return On Advertisement Spent for 7 days. <br>ROAS is the amount of revenue you have made per each unit you have spent on ads. <br>This is calculated by the following formula: <br><b>ROAS=Revenue 7/Spend * 100%</b>."
        },
        "roasDays": {
            "title": "ROAS 1/3/7/14/30 Days",
            "tooltip": "<b>ROAS(Return on Advertising Spend):</b> <br>Displays the Return On Advertisement Spent for 1/3/7/14/30 days. <br>ROAS is the amount of revenue you have made per each unit you have spent on ads. <br>This is calculated by the following formula: <br><b>ROAS=Revenue Days/Spend * 100%</b>."
        },
        "ruleActions": {
            "title": "Actions",
            "tooltip": "The action that occurs when the conditions are met.."
        },
        "ruleChanges": {
            "title": "Number of Changes",
            "tooltip": "The number of changed objects.<br><br><strong>No changes</strong>-The rule ran without changes.<br><br><strong>N-Successful</strong>-number of objects that were changed by the rule.<br><br><strong>N-Warning</strong>-number of objects that were changed with warning by the rule, such as budget limit.<br><br><strong>N-Error</strong>-number of objects that met the conditions but were not changed due to non-technical errors, such as exceed daily budget."
        },
        "ruleDateChecked": {
            "title": "Date Checked",
            "tooltip": "Action time that was described in rule description."
        },
        "ruleDescription": {
            "title": "Description",
            "tooltip": "Action, conditions and objects are displayed as they were when the rule ran.<br><br>To filter all the rule that relate to one, click on the rule row."
        },
        "ruleLogState": {
            "title": "Status",
            "tooltip": "Status shows whether the action was implemented with or without error.<br><strong>Successful</strong>-Action taken without errors.<br><strong>Apple Search Ads Notification</strong>-Action taken with limits of Apple Search Ads.<br><strong>User Notification</strong>-Action taken with limits of User.<br><strong>Apple Search Ads Warning</strong>-Action not taken with limits of Apple Search Ads.<br><strong>User Warning</strong>-Action not taken with limits of User<br><strong>Technical Error</strong>-The action did not implemented due to technical errors."
        },
        "ruleName": {
            "title": "Rule Name",
            "tooltip": "The name that will help you identify your automation rule."
        },
        "ruleRunState": {
            "title": "Status",
            "tooltip": "Status shows whether the rule ran with or without error.<br><br><strong>Ran Successfully</strong>-The rule ran without errors.<br><br><strong>Ran with warnings</strong>-The rule ran with pre-defined constraint, such as budget limit.<br><br><strong>Ran with errors</strong>-The rule ran with non-technical errors, such as exceed daily budget.<br><br><strong>Technical errors</strong>-The rule ran with technical errors."
        },
        "ruleType": {
            "title": "Rule Type",
            "tooltip": "Rule type of \"Client Partner Automation\" is visible only when client partners are impersonating accounts"
        },
        "rulesFor": {
            "title": "Create Rules For",
            "tooltip": "You can create automation rules for different campaigns, ad groups or keywords."
        },
        "searchAdsVisibilityScore": {
            "title": "SA Visibility Score",
            "tooltip": "This metric shows the visibility of an app on Apple App Store’s SearchAds position. Score is scaled between 0-100"
        },
        "searchMatch": {
            "title": "Search Match",
            "tooltip": "<b>Search Match</b> uses multiple resources to automatically<br>match your ad to relevant searches in the App Store,<br>including the metadata from your App Store listing, information<br>about similar apps in the genre and other search data."
        },
        "searchScore": {
            "title": "Popularity",
            "tooltip": "The keyword popularity score of the keyword. A higher search score means a higher search volume on the keyword."
        },
        "searchTerm": {
            "title": "Search Term",
            "tooltip": "Search terms are keywords and phrases that people have used to find the particular type of app they’re looking for."
        },
        "searchTermKeyword": {
            "title": "Keyword",
            "tooltip": "Search Term Text"
        },
        "searchTermKeywordText": {
            "title": "Search Term Keyword Text",
            "tooltip": "Search Term Keyword Text"
        },
        "searchTermKeywordId": {
            "title": "Search Term Keyword ID",
            "tooltip": "Apple Search Ads Identifier for Keyword"
        },
        "searchTermSource": {
            "title": "Search Term Source",
            "tooltip": "Search Term Source"
        },
        "searchTermText": {
            "title": "Search Term Text",
            "tooltip": "Search Term Text"
        },
        "smartBidding": {
            "title": "Smart Bidding"
        },
        "source": {
            "title": "Source",
            "tooltip": "This column shows the source of the keyword bid change. The are four source types:<br><ul><li><b>Dashboard:</b> The keyword bid is updated from the Ads Manager dashboard.</li><li><b>External:</b> The keyword bid is updated from Apple Native.</li><li><b>Automation:</b> The keyword bid is updated using an Automation rule.</li><li><b>Smart-bidding:</b> The keyword bid is updated using Smart-bidding</li></ul>"
        },
        "spend": {
            "title": "Spend",
            "tooltip": "The total amount that has been spent on this campaign so far."
        },
        "startDate": {
            "title": "Start Date",
            "tooltip": "Start Date"
        },
        "campaignStartTime": {
            "title": "Campaign Start Time",
            "tooltip": "Campaign Start Time"
        },
        "adGroupStartTime": {
            "title": "Ad Group Start Time",
            "tooltip": "Ad Group Start Time"
        },
        "stateReason": {
            "title": "Pause Reason",
            "tooltip": "Reason for the automation being paused"
        },
        "status": {
            "title": "Status",
            "tooltip": "Status"
        },
        "storefront": {
            "title": "Storefront(s)",
            "tooltip": "Displays which storefront(s) the campaign is active in"
        },
        "strategies": {
            "title": "Strategies",
            "tooltip": "This column shows assigned Automations for current date."
        },
        "strategiesWithSb": {
            "title": "Strategies",
            "tooltip": "This column shows assigned Smart Bidding and Automations for current date."
        },
        "subscriptions": {
            "title": "Subscriptions",
            "tooltip": "The number of events that comes with Subscription goal category."
        },
        "targetCPA": {
            "title": "Avg./Target CPA"
        },
        "targetCPI": {
            "title": "Avg./Target CPI"
        },
        "targetCPG": {
            "title": "Avg./Target CPG"
        },
        "targetROAS": {
            "title": "Avg./Target ROAS"
        },
        "taps": {
            "title": "Taps",
            "tooltip": "The number of times your ad was tapped on."
        },
        "todaysRank": {
            "title": "Ranking (Today)",
            "tooltip": "This is organic ranking of selected app for that keyword"
        },
        "totalBudget": {
            "title": "Total Budget (Lifetime)",
            "tooltip": "The lifetime budget allocated to a campaign."
        },
        "totalCpa": {
            "title": "Average CPA (Total)",
            "tooltip": "Total campaign spend divided by the number of total installs within the reporting period."
        },
        "totalCr": {
            "title": "Conversion Rate (Total)",
            "tooltip": "Total installs divided by the total number of taps within the reporting period."
        },
        "totalInstalls": {
            "title": "Installs (Total)",
            "tooltip": "Total number of tap-through and view-through new downloads and redownloads within the reporting period."
        },
        "totalNewDownloads": {
            "title": "New Downloads (Total)",
            "tooltip": "Total number of tap-through and view-through new downloads within the reporting period."
        },
        "totalRedownloads": {
            "title": "Redownloads (Total)",
            "tooltip": "Total number of tap-through and view-through redownloads within the reporting period."
        },
        "totalPaidKeywords": {
            "title": "Total Paid Keywords",
            "tooltip": "The number of keywords that app is running ads"
        },
        "trials": {
            "title": "Trials",
            "tooltip": "The number of events that comes with Trial goal category."
        },
        "ttr": {
            "title": "Tap-through Rate (TTR)",
            "tooltip": "<b>The Tap-through Rate (TTR)</b> <br>This is calculated by dividing your total tap numbers to your total impression numbers."
        },
        "viewInstalls": {
            "title": "Installs (View-through)",
            "tooltip": "Total number of new downloads and redownloads resulting from users who viewed your ad but didn’t tap on in it within a one-day attribution window."
        },
        "viewNewDownloads": {
            "title": "New Downloads (View-through)",
            "tooltip": "New downloads from users who viewed your ad but didn’t tap on in it and have not previously downloaded your app. These are counted within a one-day attribution window."
        },
        "viewRedownloads": {
            "title": "Redownloads (View-through)",
            "tooltip": "Redownloads from users who viewed your ad but didn’t tap on in it within one-day attribution window.These are counted when a user downloads your app, deletes it, and downloads it again on the same device or a different one following an ad view."
        },
        "yesterdaysRank": {
            "title": "Ranking (Yesterday)",
            "tooltip": "This is ranking of selected app for that keyword"
        }
    }
}
