import { ATTRIBUTION_FILTER_COLUMNS } from '@/common/FilteringUtils';

export const columns = [
    // default: is used for if column will be displayed default
    // table: is used for if column is belonging to table
    // label: is used for displaying on column selector
    // sortAscending: is used to determine column's sort direction on first click
    // common columns
    { index: 0, key: 'spend', table: ['all'] },
    { index: 1, key: 'averageCPA', table: ['all'] },
    { index: 114, key: 'totalCpa', table: ['all'] },
    { index: 2, key: 'averageCPT', table: ['all'] },
    { index: 3, key: 'impressions', table: ['all'] },
    { index: 4, key: 'taps', table: ['all'] },
    { index: 5, key: 'installs', table: ['all'] },
    { index: 108, key: 'totalInstalls', table: ['all'] },
    { index: 109, key: 'viewInstalls', table: ['all'] },
    { index: 6, key: 'ttr', table: ['all'] },
    { index: 7, key: 'conversionRate', table: ['all'] },
    { index: 115, key: 'totalCr', table: ['all'] },
    { index: 8, key: 'appName', default: false, table: ['campaign', 'campaigns', 'keywords', 'apps', 'adGroups', 'searchTerms', 'creativeSets', 'ads', 'cpp'], locked: ['apps'], sortAscending: true },
    { index: 9, key: 'campaign', default: false, table: ['campaign', 'campaigns', 'keywords', 'adGroups', 'searchTerms', 'creativeSets', 'ads'], locked: ['campaign', 'campaigns'], sortAscending: true },
    { index: 14, key: 'averageRevenuePerUser', table: ['campaign', 'campaigns', 'keywords', 'apps', 'adGroups', 'creativeSets', 'ads', 'cpp'] },
    { index: 17, key: 'attrInstalls', table: ['campaign', 'campaigns', 'keywords', 'apps', 'adGroups', 'creativeSets', 'ads', 'cpp'] },
    { index: 18, key: 'installRate', table: ['campaign', 'campaigns', 'keywords', 'apps', 'adGroups', 'creativeSets', 'ads', 'cpp'] },
    { index: 19, key: 'averageCPI', table: ['campaign', 'campaigns', 'keywords', 'apps', 'adGroups', 'creativeSets', 'ads', 'cpp'] },
    { index: 20, key: 'numberOfGoals', table: ['campaign', 'campaigns', 'keywords', 'apps', 'adGroups', 'creativeSets', 'ads', 'cpp'] },
    { index: 21, key: 'costPerGoal', table: ['campaign', 'campaigns', 'keywords', 'apps', 'adGroups', 'creativeSets', 'ads', 'cpp'] },
    { index: 22, key: 'goalsRate', table: ['campaign', 'campaigns', 'keywords', 'apps', 'adGroups', 'creativeSets', 'ads', 'cpp'] },
    { index: 23, key: 'revenuePerGoal', table: ['campaign', 'campaigns', 'keywords', 'apps', 'adGroups', 'creativeSets', 'ads', 'cpp'] },
    { index: 24, key: 'goalsRevenue', table: ['campaign', 'campaigns', 'keywords', 'apps', 'adGroups', 'creativeSets', 'ads', 'cpp'] },
    { index: 25, key: 'goalsROAS', table: ['campaign', 'campaigns', 'keywords', 'apps', 'adGroups', 'creativeSets', 'ads', 'cpp'] },
    { index: 28, key: 'newDownloads', table: ['all'] },
    { index: 110, key: 'viewNewDownloads', table: ['all'] },
    { index: 111, key: 'totalNewDownloads', table: ['all'] },
    { index: 29, key: 'redownloads', table: ['all'] },
    { index: 112, key: 'viewRedownloads', table: ['all'] },
    { index: 113, key: 'totalRedownloads', table: ['all'] },
    { index: 30, key: 'adGroupName', default: false, table: ['adgroup', 'keyword', 'keywords', 'adGroups', 'ad-groups', 'creativeSets', 'searchTerms', 'ads'], locked: ['adgroup', 'adGroups'], sortAscending: true },
    { index: 97, key: 'strategies', deafult: false, table: ['accounts', 'apps', 'campaign', 'campaigns', 'adGroups', 'ad-groups', 'keywords', 'searchTerms'] },

    // campaign columns
    { index: 31, key: 'storefront', table: ['campaign', 'campaigns', 'adGroups', 'ad-groups', 'keywords', 'searchTerms', 'search-terms', 'creativeSets', 'ads'], sortAscending: true },
    { index: 10, key: 'campaignStatus', table: ['searchTerms', 'ads'], sortAscending: true },
    { index: 32, key: 'totalBudget', table: ['campaign', 'campaigns'] },
    { index: 33, key: 'dailyBudget', table: ['campaign', 'campaigns'] },
    { index: 34, key: 'localSpend', table: ['campaign'] },
    { index: 89, key: 'adPlacement', table: ['campaigns', 'ads', 'cpp'], sortAscending: true },
    { index: 15, key: 'ltv', default: false, table: ['campaigns', 'adGroups', 'keywords'], accessibleBy: ['SA_LTV_UPLOAD'] },
    { index: 98, key: 'campaignStartTime', table: ['campaign', 'campaigns'] },

    // ad group columns
    { index: 11, key: 'adGroupStatus', table: ['searchTerms', 'ads'], sortAscending: true },
    { index: 36, key: 'searchMatch', table: ['adgroup', 'adGroups', 'ad-groups'] },
    { index: 37, key: 'defaultMaxCPTBid', table: ['adgroup', 'adGroups', 'ad-groups'] },
    { index: 38, key: 'cpaGoal', default: false, table: ['adgroup', 'adGroups', 'ad-groups'] },
    { index: 39, key: 'startDate', default: false, table: ['adgroup'] },
    { index: 40, key: 'endDate', default: false, table: ['adgroup'] },

    // keyword columns
    { index: 41, key: 'organicRank', table: ['keyword', 'keywords'] },
    { index: 42, key: 'status', table: ['keyword', 'keywords'], locked: ['keyword', 'keywords'], sortAscending: true },
    { index: 43, key: 'bidAmount', table: ['keyword', 'keywords'], locked: ['keywords'] },
    { index: 44, key: 'matchType', default: false, table: ['keyword', 'keywords'], sortAscending: true },
    { index: 45, key: 'keyword', default: false, table: ['keyword', 'keywords'], locked: ['keyword', 'keywords'], sortAscending: true },

    // cohort columns
    { index: 47, key: 'cohortGoalRevenueDays', default: false, table: ['all'] },
    { index: 52, key: 'goalRevenue1', default: false, table: ['apps', 'campaigns', 'adGroups', 'ad-groups', 'creativeSets', 'keywords', 'ads', 'cpp'] },
    { index: 53, key: 'goalRevenue3', default: false, table: ['apps', 'campaigns', 'adGroups', 'ad-groups', 'creativeSets', 'keywords', 'ads', 'cpp'] },
    { index: 54, key: 'goalRevenue7', default: false, table: ['apps', 'campaigns', 'adGroups', 'ad-groups', 'creativeSets', 'keywords', 'ads', 'cpp'] },
    { index: 55, key: 'goalRevenue14', default: false, table: ['apps', 'campaigns', 'adGroups', 'ad-groups', 'creativeSets', 'keywords', 'ads', 'cpp'] },
    { index: 56, key: 'goalRevenue30', default: false, table: ['apps', 'campaigns', 'adGroups', 'ad-groups', 'creativeSets', 'keywords', 'ads', 'cpp'] },
    { index: 90, key: 'goalRevenue60', default: false, table: ['apps', 'campaigns', 'adGroups', 'ad-groups', 'creativeSets', 'keywords', 'ads', 'cpp'] },
    { index: 49, key: 'goalsRoasDays', default: false, table: ['campaign', 'campaigns', 'keyword', 'keywords', 'apps', 'adGroups', 'creativeSets', 'ads'] },
    { index: 57, key: 'goalRoas1', default: false, table: ['apps', 'campaigns', 'adGroups', 'ad-groups', 'creativeSets', 'keywords', 'ads', 'cpp'] },
    { index: 58, key: 'goalRoas3', default: false, table: ['apps', 'campaigns', 'adGroups', 'ad-groups', 'creativeSets', 'keywords', 'ads', 'cpp'] },
    { index: 59, key: 'goalRoas7', default: false, table: ['apps', 'campaigns', 'adGroups', 'ad-groups', 'creativeSets', 'keywords', 'ads', 'cpp'] },
    { index: 60, key: 'goalRoas14', default: false, table: ['apps', 'campaigns', 'adGroups', 'ad-groups', 'creativeSets', 'keywords', 'ads', 'cpp'] },
    { index: 61, key: 'goalRoas30', default: false, table: ['apps', 'campaigns', 'adGroups', 'ad-groups', 'creativeSets', 'keywords', 'ads', 'cpp'] },
    { index: 99, key: 'goalRoas60', default: false, table: ['apps', 'campaigns', 'adGroups', 'ad-groups', 'creativeSets', 'keywords', 'ads', 'cpp'] },
    { index: 83, key: 'goalCount1', default: false, table: ['apps', 'campaigns', 'adGroups', 'ad-groups', 'creativeSets', 'keywords', 'ads', 'cpp'] },
    { index: 84, key: 'goalCount3', default: false, table: ['apps', 'campaigns', 'adGroups', 'ad-groups', 'creativeSets', 'keywords', 'ads', 'cpp'] },
    { index: 85, key: 'goalCount7', default: false, table: ['apps', 'campaigns', 'adGroups', 'ad-groups', 'creativeSets', 'keywords', 'ads', 'cpp'] },
    { index: 86, key: 'goalCount14', default: false, table: ['apps', 'campaigns', 'adGroups', 'ad-groups', 'creativeSets', 'keywords', 'ads', 'cpp'] },
    { index: 87, key: 'goalCount30', default: false, table: ['apps', 'campaigns', 'adGroups', 'ad-groups', 'creativeSets', 'keywords', 'ads', 'cpp'] },
    { index: 91, key: 'goalCount60', default: false, table: ['apps', 'campaigns', 'adGroups', 'ad-groups', 'creativeSets', 'keywords', 'ads', 'cpp'] },

    // account
    { index: 51, key: 'orgName', table: ['accounts', 'apps', 'campaigns', 'adGroups', 'ad-groups', 'creativeSets', 'keywords', 'searchTerms', 'ads'], locked: ['accounts'], sortAscending: true },

    // creative set
    { index: 62, key: 'creativeSetName', table: ['creativeSets'], locked: ['creativeSets'], sortAscending: true },
    { index: 63, key: 'creativeSetStatus', table: ['creativeSets'], sortAscending: true },

    // search term
    { index: 64, key: 'searchTermText', table: ['searchTerms'], locked: ['searchTerms'], sortAscending: true },
    { index: 65, key: 'searchTermSource', table: ['searchTerms'], sortAscending: true },
    { index: 67, key: 'searchTermKeyword', table: ['searchTerms'], sortAscending: true },
    { index: 12, key: 'addedAsKeyword', table: ['searchTerms'], sortAscending: true },
    { index: 102, key: 'shareOfVoice', table: ['searchTerms', 'keywords'] },

    //app
    { index: 66, key: 'categoryRank', table: ['apps'] },

    { index: 68, key: 'displayStatus', table: ['campaigns'], sortAscending: true },
    { index: 100, key: 'adGroupDisplayStatus', table: ['adGroups'], sortAscending: true },
    { index: 101, key: 'keywordDisplayStatus', table: ['keywords'], sortAscending: true },
    { index: 103, key: 'adDisplayStatus', table: ['ads'], sortAscending: true },
    { index: 69, key: 'age', table: ['adGroups'] },
    { index: 70, key: 'gender', table: ['adGroups'], sortAscending: true },
    { index: 71, key: 'deviceClass', table: ['adGroups'], sortAscending: true },
    { index: 72, key: 'customerType', table: ['adGroups'], sortAscending: true },

    { index: 73, key: 'label', table: ['keywords', 'adGroups', 'campaigns'], sortAscending: true },

    //keyword columns
    { index: 74, key: 'difficultyRank', table: ['keyword', 'keywords'] },
    { index: 75, key: 'popularityRank', table: ['keyword', 'keywords'] },
    { index: 76, key: 'keywordId', table: ['keyword', 'keywords', 'searchTerms'], sortAscending: true },
    { index: 77, key: 'appId', table: ['apps', 'campaigns', 'adGroups', 'ad-groups', 'creativeSets', 'keywords', 'searchTerms', 'ads', 'cpp'], sortAscending: true },
    { index: 78, key: 'campaignId', table: ['campaigns', 'adGroups', 'ad-groups', 'creativeSets', 'keywords', 'searchTerms', 'ads'], sortAscending: true },
    { index: 79, key: 'adGroupId', table: ['adGroups', 'ad-groups', 'creativeSets', 'keywords', 'searchTerms', 'ads'], sortAscending: true },
    { index: 80, key: 'suggestedBidAmount', table: ['keyword', 'keywords'] },
    { index: 81, key: 'topAppsByAppleSOV', table: ['keyword', 'keywords'] },
    { index: 82, key: 'bidStrength', table: ['keyword', 'keywords'] },
    { index: 88, key: 'lastBudgetUpdate', table: ['campaigns'] },
    { index: 92, key: 'lastBidUpdate', table: ['keywords'] },
    // { index: 95, key: 'EMPTY SLOT', table: [] }, // use for the next column addition

    //Ads table columns
    { index: 93, key: 'adId', table: ['ads'], sortAscending: true },
    { index: 94, key: 'adName', table: ['ads'], locked: ['ads'], sortAscending: true },
    { index: 96, key: 'creativeType', table: ['ads', 'cpp'], sortAscending: true },

    //CPP table columns
    { index: 104, key: 'productPageName', table: ['cpp'], locked: ['cpp'], sortAscending: true },
    { index: 105, key: 'productPageId', table: ['ads', 'cpp'], sortAscending: true },
    { index: 106, key: 'numberOfAds', table: ['cpp'] },
    { index: 107, key: 'assets', table: ['ads', 'cpp'] },
];

export const columnsHiddenOnEventTime = [52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 99, 21, 25, 83, 84, 85, 86, 87];

export const defaultGroups = [
    { key: 0, tk: 'adPerformance', title: 'Ad Performance', columns: [15, 19, 0, 3, 89, 4, 2, 6, 7, 5, 17, 18, 1, 29, 28, 41, 74, 75, 66, 80, 82, 81, 102, 108, 109, 110, 111, 112, 113, 114, 115] }, // tk: translationKey
    { key: 1, tk: 'inAppConversions', title: 'In-App Conversions', columns: [20, 21, 22, 23, 14, 24, 25] },
    { key: 2, tk: 'cohortAnalysis', title: 'Cohort Analysis', columns: [52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 99, 83, 84, 85, 86, 87] },
    { key: 3, tk: 'settings', title: 'Settings', columns: [33, 31, 32, 8, 51, 9, 30, 8, 42, 45, 37, 43, 44, 10, 11, 12, 13, 36, 62, 63, 65, 67, 68, 100, 101, 69, 70, 71, 72, 73, 38, 76, 77, 78, 79, 93, 94, 96, 97, 98, 103, 104, 105, 106, 107, 88, 92] },
    { key: 4, tk: 'customColumns', title: 'Custom Columns', columns: [] },
];

//Spend, Impression, Taps, Installs, TTR, CR, CPA
const statusColumnMap = {
    campaigns: 68,
    adGroups: 100,
    keywords: 101,
};
const commonDefaultColumns = [0, 3, 4, 5, 6, 7, 1];
export const defaultPresets = {
    accounts: [0, 3, 4, 5, 6, 7, 2, 1, 97],
    apps: [...commonDefaultColumns, 97, 20],
    campaigns: [...commonDefaultColumns, 97, 20, 31, 33],
    adGroups: [...commonDefaultColumns, 97, 20, 21],
    keywords: [...commonDefaultColumns, 97, 20, 21],
    searchTerms: [...commonDefaultColumns, 97, 2],
    ads: [...commonDefaultColumns, 103, 31],
    cpp: [107, 106, 0, 1, 4, 5, 6, 7],
};

// by default they are true
columns.forEach(c => c.default = c.default !== false);

export function getColumns(tableName, eventTime = false) {
    if (!tableName) {
        return columns;
    }
    return columns.filter((c) => {
        const belongsToTable = c.table.includes('all') || c.table.includes(tableName);
        return belongsToTable && (!eventTime || !columnsHiddenOnEventTime.includes(c.index));
    });
}

export function getColumnLevels(columnName) {
    return columns
        .find(c => c.key.includes(columnName))?.table;
}

export function defaultPreset(tableName, isEventTime = false) {
    const cols = [...defaultPresets[tableName]];
    if (statusColumnMap[tableName]) { //add status column
        cols.unshift(statusColumnMap[tableName]);
    }
    if (!isEventTime) {
        return cols;
    }
    return cols.filter(index => !columnsHiddenOnEventTime.includes(index));
}

export function defaultIndexes(tableName) {
    return getColumns(tableName)
        .filter(c => c.default && !(c.locked && c.locked.includes(tableName)))
        .map(c => c.index);
}


export function getAttributionColumns(tableName) {
    const attributionColumns = defaultGroups // find attribution columns
        .filter(d => ['inAppConversions', 'cohortAnalysis'].includes(d.tk))
        .flatMap(d => d.columns);
    return columns
        .filter(col => attributionColumns.includes(col.index)) // filter attribution columns
        .filter(col => (col.table.includes('all') || col.table.includes(tableName))); // filter by table view
}

export function getProOnlyColumns() {
    return ATTRIBUTION_FILTER_COLUMNS.filter(c => c.forProUsersOnly).map(c => c.name);
}

export function isAttributionColumn(columnName) {
    const attributionColumns = defaultGroups
        .filter(d => ['inAppConversions', 'cohortAnalysis'].includes(d.tk))
        .flatMap(d => d.columns);
    return !!columns
        .filter(col => attributionColumns.includes(col.index))
        .find(col => col.key.includes(columnName));
}


const getFirstSortDescendingColumnIndexes = () => {
    return getColumns().filter(c => !c.sortAscending).map(c => c.index);
};

export function isFirstSortDescending(columnIndex) {
    return getFirstSortDescendingColumnIndexes().includes(columnIndex);
}
