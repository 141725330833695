import { makeRequest } from '@/common/MaRequestPooler';
import { getAsArray, paramsToQuery } from '@/common/MaUtils.mjs';
import { searchTabFormatter } from '@/pages/Manage/ManageUtils.mjs';

export class ReportController {
    constructor(pooler) {
        this.requestPooler = pooler || makeRequest;
    }
    fetchTableData({ data, requestLevel }) {
        let url = `/sa/dashboard/report/${ requestLevel }/table`;
        return  this.requestPooler(url, {
            method: 'POST',
            data,
            formatter: searchTabFormatter(requestLevel),
        });
    }

    exportData({ data, requestLevel, attributedTime }) {
        let url = `/sa/dashboard/report/${ requestLevel }/export?${ paramsToQuery({ attributedTime }) }`;
        return this.requestPooler(url, {
            method: 'POST',
            data,
        });
    }

    reportNewSummaryData({ data, requestLevel }) {
        let url = `/sa/dashboard/report/${ requestLevel }/summary`;
        return  this.requestPooler(url, {
            method: 'POST',
            data,
        });
    }

    reportSeriesData({ data, requestLevel }) {
        let url = `/sa/dashboard/report/${ requestLevel }/daily`;
        return  this.requestPooler(url, {
            method: 'POST',
            data,
        });
    }

    reportCurrentTime(data) {
        return this.requestPooler(`/sa/dashboard/report/campaigns/current-time`, {
            method: 'POST',
            data: getAsArray(data),
        });
    }


    callLatestData(data) {
        return this.requestPooler(`/sa/dashboard/report/account/queue`, {
            method: 'POST',
            data: getAsArray(data),
        });
    }

    getBidHistoryLogs({ orgId, entityLevel, entityId, startDate, endDate }) {
        return this.requestPooler(`/sa/bid-history-logs/org/${orgId}/level/${entityLevel}/entity/${entityId}?${ paramsToQuery({ startDate, endDate }) }`);
    }

    getBidHistoryDailyData(country, data) {
        return  this.requestPooler(`/sa/dashboard/report/country/${country}/bid-daily`, {
            method: 'POST',
            data,
        });
    }

    getPopularitySeriesData({ store, countryCode, keyword, startDate, endDate }) {
        return this.requestPooler(`/keyword/search-score-history/store/${store}/country/${countryCode}?${paramsToQuery({ startDate, endDate, keyword })}`);
    }

    getBudgetHistoryLogs({ orgId, campaignId, type, size, page, startDate, endDate }) {
        return this.requestPooler(`/sa/budget-history-logs/org/${orgId}/campaign/${campaignId}/type/${type}?${paramsToQuery({ size, page, startDate, endDate })}`);
    }

    getBudgetHistoryDailyData(type, data) {
        return this.requestPooler(`/sa/dashboard/report/type/${type}/budget-daily`, {
            method: 'POST',
            data,
        });
    }

    getStrategyData({ metadataLevel, data }) {
        return  this.requestPooler(`/sa/automation/affecting-metadata/${metadataLevel}`, {
            method: 'POST',
            data: getAsArray(data),
        });
    }

    /**
     * fetches compare daily for given request level
     * @param requestLevel
     * @param orgId
     * @param campaignId
     * @param startDate
     * @param endDate
     * @param adGroupId
     * @returns {*}
     */

    getAdsPerformanceData({ orgId, campaignId, startDate, endDate, adGroupId }) {
        let url = `/sa/dashboard/report/org/${orgId}/campaign/${campaignId}/adgroup/${adGroupId}/ad-performance?${paramsToQuery({
          startDate,
          endDate })}`;
        return this.requestPooler(url);
    }

    /**
     * fetches search term percentage data for given keyword and metric
     * @param orgId
     * @param keywordId
     * @param metric spend | impressions | taps | installs
     * @returns {*}
     */
    getSearchTermPercentageOfMetric({ orgId, keywordId, metric, startDate, endDate }) {
        const url = `/sa/dashboard/report/orgs/${orgId}/keywords/${keywordId}/metrics/${metric}/search-term-performance?${paramsToQuery({ startDate, endDate })}`;
        return this.requestPooler(url);
    }
}

export const useReportController = () => new ReportController();


