export const PARTNER_ICONS = {
    appsflyer: '/icons/appsflyer.png',
    adjust: '/icons/ajust.png',
    kochava: '/icons/kochava.png',
    branch: '/icons/branch.png',
    singular: '/icons/singular.png',
    custom: '/icons/custom.png',
    airbridge: '/icons/airbridge.png',
    tenjin: '/icons/tenjin.png',
};

export const PARTNERS = [
    {
        id: 'APPSFLYER',
        titleKey: 'common.integrations.partners.appsflyer',
        classes: ['appsflyer'],
        comingSoon: [],
        logo: PARTNER_ICONS.appsflyer,
        index: 2,
    },
    {
        id: 'ADJUST',
        titleKey: 'common.integrations.partners.adjust',
        classes: ['ajust'],
        comingSoon: [],
        logo: PARTNER_ICONS.adjust,
        index: 1,
    },
    {
        id: 'BRANCH',
        titleKey: 'common.integrations.partners.branch',
        classes: ['branch'],
        comingSoon: [],
        logo: PARTNER_ICONS.branch,
        index: 3,
    },
    {
        id: 'KOCHAVA',
        titleKey: 'common.integrations.partners.kochava',
        classes: ['kochava'],
        comingSoon: [],
        logo: PARTNER_ICONS.kochava,
        index: 4,
    },
    {
        id: 'SINGULAR',
        titleKey: 'common.integrations.partners.singular',
        classes: ['singular'],
        comingSoon: [],
        logo: PARTNER_ICONS.singular,
        index: 5,
    },
    {
        id: 'AIRBRIDGE',
        titleKey: 'common.integrations.partners.airbridge',
        classes: ['airbridge'],
        comingSoon: [],
        logo: PARTNER_ICONS.airbridge,
        index: 6,
    },
    {
        id: 'CUSTOM',
        titleKey: 'common.integrations.partners.custom',
        classes: ['custom'],
        comingSoon: [],
        logo: PARTNER_ICONS.custom,
        index: 7,
    },
    {
        id: 'TENJIN',
        titleKey: 'common.integrations.partners.tenjin',
        classes: ['tenjin'],
        comingSoon: ['tenjin'],
        logo: PARTNER_ICONS.tenjin,
        index: 8,
    },
];
