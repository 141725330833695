{
    "cryptoCurrencies": {
        "BITCOIN": {
            "label": "比特币",
            "code": "BTC"
        },
        "ETHEREUM": {
            "label": "以太坊",
            "code": "ETH"
        },
        "RIPPLE": {
            "label": "XRP",
            "code": "XRP"
        },
        "TETHER": {
            "label": "拴",
            "code": "USDT"
        },
        "DOGECOIN": {
            "label": "狗狗币",
            "code": "DOGE"
        },
        "BONK": {
            "label": "邦克",
            "code": "邦克"
        },
        "SOLANA": {
            "label": "索拉纳",
            "code": "SOL"
        },
        "LITECOIN": {
            "label": "莱特币",
            "code": "LTC"
        },
        "PEPE": {
            "label": "佩佩",
            "code": "PEPE"
        },
        "CARDANO": {
            "label": "卡达诺",
            "code": "ADA"
        },
        "WORLDCOIN": {
            "label": "世界币",
            "code": "WLD"
        },
        "TRON": {
            "label": "TRON",
            "code": "TRX"
        },
        "IOTA": {
            "label": "IOTA",
            "code": "IOTA"
        }
    },
    "headers": {
        "adGroupLabel": {
            "title": "广告组标签",
            "tooltip": "由用户描述的标签"
        },
        "application": {
            "title": "应用程序"
        },
        "apps": {
            "title": "应用程序"
        },
        "suggestedBidAmount": {
            "title": "建议投标金额"
        },
        "campaignLabel": {
            "title": "活动标签",
            "tooltip": "由用户描述的标签"
        },
        "displayStatus": {
            "title": "状态",
            "tooltip": "状态"
        },
        "keywordLabel": {
            "title": "关键词标签",
            "tooltip": "由用户描述的标签"
        },
        "optimization": {
            "title": "战略"
        },
        "rank": {
            "title": "等级",
            "tooltip": "所选应用程序在每个关键词上的排名。"
        },
        "rankVsRank": {
            "title": "等级与等级",
            "tooltip": "所选应用程序在每个关键词上的排名。"
        },
        "smartBidding": {
            "title": "智能投标"
        },
        "targetCPA": {
            "title": "平均\/目标 CPA"
        },
        "targetCPI": {
            "title": "平均\/目标消费物价指数"
        },
        "targetCPG": {
            "title": "平均\/目标 CPG"
        },
        "targetROAS": {
            "title": "平均\/目标 ROAS"
        },
        "totalCpa": {
            "title": "平均 CPA（总计）",
            "tooltip": "活动总支出除以报告期内的总安装次数。"
        },
        "totalCr": {
            "title": "转换率（总计）",
            "tooltip": "安装总数除以报告期内的水龙头总数。"
        },
        "totalInstalls": {
            "title": "安装（总计）",
            "tooltip": "报告期内新下载和重新下载的点击和浏览总数。"
        },
        "totalNewDownloads": {
            "title": "新下载量（总计）",
            "tooltip": "报告期内新的点击下载和浏览下载总数。"
        },
        "totalRedownloads": {
            "title": "重新下载（总计）",
            "tooltip": "报告期内点击通过和查看通过重新下载的总数。"
        },
        "viewInstalls": {
            "title": "安装（透视）",
            "tooltip": "在一天的归因窗口内，浏览了您的广告但没有点击广告的用户所产生的新下载和重新下载总数。"
        },
        "viewNewDownloads": {
            "title": "新下载（浏览）",
            "tooltip": "新下载用户，他们观看了您的广告，但没有点击广告，之前也没有下载过您的应用程序。这些用户会在一天的归因窗口内被计算在内。"
        },
        "viewRedownloads": {
            "title": "重新下载（浏览）",
            "tooltip": "当用户下载了您的应用程序后删除了它，并在同一设备或不同设备上观看广告后再次下载时，这些数据将被计算在内。"
        },
        "GROUP_BY_CAMPAIGN_TYPE": {
            "title": "广告系列类型",
            "tooltip": "设置 Apple Search Ads Advanced 广告系列结构的方式会影响广告系列效果、报告显示的数据，以及扩展能力。为帮助广告系列取得理想效果，建议你按照关键词主题或策略将各个广告系列区分开来。首先，不妨创建以下四种广告系列类型：<br><strong>品牌广告系列<\/strong> 主要覆盖那些搜索你的 app 或公司名称的用户。<br><strong>类别广告系列<\/strong> 主要覆盖那些搜索 app 所属类别及其应用场景等非品牌搜索词的用户。<br><strong>竞品广告系列<\/strong> 主要覆盖那些搜索同类别或相关类别中类似 app 的用户。<br><strong>发现型广告系列<\/strong> 主要挖掘与你的app相关的搜索词。"
        },
        "actionFrequency": {
            "title": "操作频率",
            "tooltip": "可以设定重复执行同一操作的最小时间。"
        },
        "actions": {
            "title": "操作",
            "tooltip": "操作"
        },
        "addedAsKeyword": {
            "title": "作为关键词添加"
        },
        "adChannelType": {
            "title": "广告频道"
        },
        "adGroupId": {
            "title": "广告组ID",
            "tooltip": "广告组的Apple Search Ads识别子"
        },
        "adGroup": {
            "title": "广告组",
            "tooltip": "广告组"
        },
        "adGroupName": {
            "title": "广告组名称",
            "tooltip": "广告组"
        },
        "adGroupDisplayStatus": {
            "title": "广告组状态",
            "tooltip": "广告组状态"
        },
        "adGroupStatus": {
            "title": "广告组状态",
            "tooltip": "广告组状态"
        },
        "adId": {
            "title": "广告 ID",
            "tooltip": "Apple 搜索广告的广告标识符。"
        },
        "adName": {
            "title": "广告名称",
            "tooltip": "广告名称"
        },
        "adPlacement": {
            "title": "广告展示位置",
            "tooltip": "广告展示位置"
        },
        "adStatus": {
            "title": "广告状态",
            "tooltip": "广告状态"
        },
        "age": {
            "title": "年龄范围",
            "tooltip": "年龄范围"
        },
        "ageRange": {
            "title": "年龄范围",
            "tooltip": "年龄范围"
        },
        "allConversionsRate": {
            "title": "总转化率",
            "tooltip": "就总安装数而言包含未被标识为目标的所有转化的比率"
        },
        "app": {
            "title": "应用程序",
            "tooltip": "应用程序"
        },
        "appId": {
            "title": "应用程序ID",
            "tooltip": "应用程序的Apple Search Ads识别子"
        },
        "shareOfVoice": {
            "title": "苹果话语权份额",
            "tooltip": "在相同国家和地区，您的广告在相同搜索词或关键词的总展示量中所占的份额。Apple SOV 是上周的数据。"
        },
        "appleShareOfVoiceDaily": {
            "title": "苹果语音份额（每日）"
        },
        "appleShareOfVoiceWeekly": {
            "title": "苹果语音份额（每周）"
        },
        "appName": {
            "title": "应用程序",
            "tooltip": "应用程序"
        },
        "appShare": {
            "title": "点击率",
            "tooltip": "当前应用程序的点击率"
        },
        "appliedData": {
            "title": "应用数据",
            "tooltip": "实行自动匹配的对象数据。"
        },
        "arpu": {
            "title": "@:headers.averageRevenuePerUser.title",
            "tooltip": "@:headers.averageRevenuePerUser.tooltip"
        },
        "assets": {
            "title": "资产",
            "tooltip": "资产根据应用程序的主要语言和 iPhone 6.5'' 显示屏显示。"
        },
        "attrInstallRate": {
            "title": "@:headers.installRate.title",
            "tooltip": "@:headers.installRate.tooltip"
        },
        "attrInstalls": {
            "title": "安装(归因)",
            "tooltip": "第三方服务提供商衡量到的安装次数。下载后初次启动的次数。 <br>显示N\/A时请确认你的API连接。"
        },
        "automationActions": {
            "title": "操作",
            "tooltip": "满足自动匹配规则条件时会执行的操作。"
        },
        "automationConditions": {
            "title": "条件",
            "tooltip": "自动匹配规则执行的条件。"
        },
        "automationCreatedAt": {
            "title": "创造于",
            "tooltip": "自动匹配规则创造的日期。"
        },
        "automationName": {
            "title": "名称",
            "tooltip": "自动匹配规则的名称。"
        },
        "automationState": {
            "title": "状态",
            "tooltip": "自动匹配规则的状态。"
        },
        "automationUpdatedAt": {
            "title": "更新于",
            "tooltip": "自动化规则的更新日期。"
        },
        "averageCPA": {
            "shortTitle": "平均CPA",
            "title": "平均 CPA（直通）",
            "tooltip": "活动总支出除以报告期内的直通安装次数。"
        },
        "averageCPI": {
            "title": "CPI",
            "tooltip": "<b>平均CPI（每个安装费用）:<\/b> <br>以总广告花费除以总安装计算。 <br><br>显示N\/A时请确认你的API连接。"
        },
        "averageCPT": {
            "shortTitle": "平均CPT",
            "title": "平均CPT",
            "tooltip": "<b>平均每个点击费用(CPT)<\/b> <br>你为每个点击支付的平均费用。 <br>是以总花费除以总点击次数计算。"
        },
        "averageRevenuePerUser": {
            "title": "ARPU",
            "tooltip": "<b>ARPU（每个用户的平均利润）:<\/b> <br>显示每个用户的平均利润。 <br>是以总利润除以总安装计算。"
        },
        "bidAmount": {
            "title": "出价金额"
        },
        "budgetChangeSource": {
            "title": "资料来源",
            "tooltip": "此列显示活动每日预算变化的来源。有四种来源类型：<br><ul> <li> <b> 仪表板：<\/b> 广告系列的每日预算从广告管理器仪表板更新。<\/li><li> <b> 外部：<\/b><\/li><li> <b> Automation：<\/b><\/li><li> <b> 预算分配：<\/b><\/li><li> <b> Quick Automation（快速自动化）：<\/b> 使用优化更新广告系列的每日预算。<\/li><\/ul>"
        },
        "defaultBidAmount": {
            "title": "默认投标金额"
        },
        "bidStrength": {
            "title": "出价力度",
            "tooltip": "<b>出价力度弱 (red)<\/b>表示你的出价不足以与其他广告商的出价竞争。你的广告被显示的可能性相对地较低。 <br> <b>出价力度适中 (yellow)<\/b> 表示你可以提高出价令的的广告有更多的显示机会。 <br> <b>出价力度适强 (green)<\/b>表示你的出价比你的竞争对手有更良好的出价。 <br><b>没有指标<\/b>将在没有足够的数据建议出价时显示。例如关键词已经暂停、广告组或广告系列已经暂停、或是你的出价已经和我们的建议一致。"
        },
        "campaign": {
            "title": "广告系列",
            "tooltip": "已连接的广告系列名称"
        },
        "campaignDailyBudget": {
            "title": "日预算上限",
            "tooltip": "一天可花费的总金额。如果您的终生预算未满，该值将每日重置。"
        },
        "campaignId": {
            "title": "广告系列ID",
            "tooltip": "广告系列的Apple Search Ads识别子"
        },
        "campaignStatus": {
            "title": "状态",
            "tooltip": "广告系列状况"
        },
        "campaignTotalBudget": {
            "title": "终身预算",
            "tooltip": "分配给活动的终生预算。"
        },
        "categoryName": {
            "title": "分类名称",
            "tooltip": "应用程序所属的分类"
        },
        "categoryRank": {
            "title": "分类级别",
            "tooltip": "将应用程序按分类排序"
        },
        "categoryRanking": {
            "title": "类别排名",
            "tooltip": "类别排名"
        },
        "chance": {
            "title": "Chance",
            "tooltip": "机会分数会显示搜索特定关键词时任何有机会进入TOP 10的可能性。 <br>机会分数由0到100，<br>100分的关键词最容易进入TOP 10的搜索结果。"
        },
        "competitors": {
            "title": "竞品",
            "tooltip": "添加你的竞争对手以获取关于他们的建议和分析"
        },
        "conditions": {
            "title": "条件",
            "tooltip": "自动匹配规则执行的条件。"
        },
        "conversionRate": {
            "title": "转换率（直通）",
            "tooltip": "报告期内安装的自来水龙头总数除以自来水龙头总数。"
        },
        "costPerConversion": {
            "title": "每次转化费用",
            "tooltip": "<b>每次转化费用<\/b> <br>你为每个转化支付的平均费用。 <br>是以总花费除以总转化量计算。"
        },
        "costPerGoal": {
            "title": "每次达到目标的费用",
            "tooltip": "你为每次达到目标支付的平均费用。"
        },
        "country": {
            "title": "国家"
        },
        "cpaGoal": {
            "title": "目标CPA",
            "tooltip": "<h3>CPA 目标<\/h3>每次转化费用(CPA)目标是你在理想状态下愿意为转化支付的金额。设置CPA目标是完全可选的。 <br><b>提示：设置一个现实的目标。 <\/b><br>设置一个你现实可以为一个转化负担得起的费用。如果你的CPA目标设魏太低，可能会错过有价值的展示次数和点击数。"
        },
        "cpi": {
            "title": "@:headers.averageCPI.title",
            "tooltip": "@:headers.averageCPI.tooltip"
        },
        "cr": {
            "title": "转换率（直通）",
            "tooltip": "报告期内安装的自来水龙头总数除以自来水龙头总数。"
        },
        "creativeSetName": {
            "title": "Creative Set",
            "tooltip": "Creative Set"
        },
        "creativeSetStatus": {
            "title": "Creative Set状态",
            "tooltip": "Creative Set状态"
        },
        "creativeType": {
            "title": "创意类型",
            "tooltip": "创意类型"
        },
        "csvReport": {
            "title": "CSV报告",
            "tooltip": "CSV报告包含现正运作中的规则。 <br><br>非运作中的规则不能套用到报告中。"
        },
        "currency": {
            "title": "货币"
        },
        "currentBid": {
            "title": "当前出价",
            "tooltip": "当前出价"
        },
        "currentDailyBudget": {
            "title": "当前每日预算",
            "tooltip": "当前每日预算"
        },
        "currentLifetimeBudget": {
            "title": "目前的终身预算",
            "tooltip": "目前的终身预算"
        },
        "customColumn": {
            "new": "新的自定义列",
            "title": "自定义列"
        },
        "customerType": {
            "title": "用户类型",
            "tooltip": "你可以在广告组级别中按下列客户类型优化你的受众:<br><br>所有用户 <br>新用户<br>回锅用户<br>你其他应用程序的用户"
        },
        "dailyBudget": {
            "title": "日预算上限",
            "tooltip": "一天中可以花费的预算上限。没有到达上限的话每天都会重置。"
        },
        "dayPart": {
            "title": "分时段",
            "tooltip": "分时段"
        },
        "defaultAmount": {
            "title": "@:headers.defaultMaxCPTBid.title",
            "tooltip": "@:headers.defaultMaxCPTBid.tooltip"
        },
        "defaultCPTAmount": {
            "title": "默认CPT出价",
            "tooltip": "<b>默认最高CPT出价<\/b> <br>这是你愿意为一个广告点击支付的最大金额。这个出价将会应用于所有关键词<br>除非你设定了个别关键词的出价."
        },
        "defaultMaxCPMBid": {
            "title": "默认CPM出价",
            "tooltip": "<b>默认最高CPM出价<\/b> <br>这是你愿意支付的最大金额<br>为一个广告点击"
        },
        "defaultMaxCPTBid": {
            "title": "默认CPT出价",
            "tooltip": "<b>默认最高CPT出价<\/b> <br>这是你愿意为一个广告点击支付的最大金额。这个出价将会应用于所有关键词<br>除非你设定了个别关键词的出价."
        },
        "deviceClass": {
            "title": "设备",
            "tooltip": "设备类别"
        },
        "difficultyRank": {
            "title": "难易度",
            "tooltip": "难易度是衡量应用程序在关键词自然量排行的难度的指标。阅读更多关于 <a href=\"https:\/\/help.mobileaction.co\/en\/articles\/4201403-what-is-difficulty-score\" target=\"_blank\" style=\"color: #1c8de0\">难易度<\/a>。"
        },
        "dimensions": {
            "title": "维度",
            "tooltip": "维度是指可包含在自定义报告中的 Apple Search Ads 广告系列元素，例如广告系列 ID 或每次点击费用出价。在自定义报告中，维度以行的形式显示。"
        },
        "adDisplayStatus": {
            "title": "广告显示状态",
            "tooltip": "这是本地平台显示的只读状态。"
        },
        "downloadPercentage": {
            "title": "自然下载占比",
            "tooltip": "由自然下载构成的头部关键词"
        },
        "endDate": {
            "title": "结束日期",
            "tooltip": "结束日期"
        },
        "engagements": {
            "title": "参与数",
            "tooltip": "参与目标类别所带来的事件数量"
        },
        "frequency": {
            "title": "检查频率",
            "tooltip": "作为自动化规则的判定条件来判断是否满足条件"
        },
        "gameEvents": {
            "title": "游戏活动",
            "tooltip": "游戏事件目标类别所带来的事件数量"
        },
        "gender": {
            "title": "性别",
            "tooltip": "性别"
        },
        "goalCount1": {
            "title": "目标数量1",
            "tooltip": "你的应用程序1天内达到目标的数量。"
        },
        "goalCount14": {
            "title": "目标数量14",
            "tooltip": "你的应用程序14天内达到目标的数量。"
        },
        "goalCount3": {
            "title": "目标数量3",
            "tooltip": "你的应用程序3天内达到目标的数量。"
        },
        "goalCount30": {
            "title": "目标数量30",
            "tooltip": "你的应用程序30天内达到目标的数量。"
        },
        "goalCount60": {
            "title": "目标计数 60",
            "tooltip": "您的应用程序在 60 天内实现的目标总数。"
        },
        "goalCount7": {
            "title": "目标数量7",
            "tooltip": "你的应用程序7天内达到目标的数量。"
        },
        "goalRevenue": {
            "title": "@:headers.goalsRevenue.title",
            "tooltip": "@:headers.goalsRevenue.tooltip"
        },
        "goalRevenue1": {
            "title": "收入1",
            "tooltip": "安装1天内的总收入"
        },
        "goalRevenue14": {
            "title": "收入14",
            "tooltip": "安装14天内的总收入"
        },
        "goalRevenue3": {
            "title": "收入3",
            "tooltip": "安装3天内的总收入"
        },
        "goalRevenue30": {
            "title": "收入30",
            "tooltip": "安装30天内的总收入"
        },
        "goalRevenue60": {
            "title": "目标 收入 60",
            "tooltip": "您的广告系列在 60 天内为您的应用程序带来的总收入。"
        },
        "goalRevenue7": {
            "title": "收入7",
            "tooltip": "安装7天内的总收入"
        },
        "goalRoas": {
            "title": "@:headers.goalsROAS.title",
            "tooltip": "@:headers.goalsROAS.tooltip"
        },
        "goalRoas1": {
            "title": "目标ROAS 1",
            "tooltip": "安装1天内的广告支出回报率。 <br>该指标考虑了自定义转换下被绑定为「目标」的应用内事件所带来的收入。<br><b>总收入1\/支出*100%<\/b>."
        },
        "goalRoas14": {
            "title": "目标ROAS 14",
            "tooltip": "安装14天内的广告支出回报率。 <br>该指标考虑了自定义转换下被绑定为「目标」的应用内事件所带来的收入。<br><b>总收入14\/支出*100%<\/b>."
        },
        "goalRoas3": {
            "title": "目标ROAS 3",
            "tooltip": "安装3天内的广告支出回报率。 <br>该指标考虑了自定义转换下被绑定为「目标」的应用内事件所带来的收入。<br><b>总收入3\/支出*100%<\/b>."
        },
        "goalRoas30": {
            "title": "目标ROAS 30",
            "tooltip": "安装30天内的广告支出回报率。 <br>该指标考虑了自定义转换下被绑定为「目标」的应用内事件所带来的收入。<br><b>总收入30\/支出*100%<\/b>."
        },
        "goalRoas60": {
            "title": "目标 ROAS 60",
            "tooltip": "60 天广告总支出产生的收益率。<br>该指标考虑了自定义转换下每个已确定目标的收入。<br><b> 目标收入 60\/支出 * 100%<\/b> 。"
        },
        "goalRoas7": {
            "title": "目标ROAS 7",
            "tooltip": "安装7天内的广告支出回报率。 <br>该指标考虑了自定义转换下被绑定为「目标」的应用内事件所带来的收入。<br><b>总收入7\/支出*100%<\/b>."
        },
        "goalsROAS": {
            "title": "目标活动ROAS",
            "tooltip": "表示从总广告支出中产生收入的比率。此项目将计算从被绑定为「目标」的应用内事件而来的转化。<br><b>目标收入\/支出*100%<\/b>。"
        },
        "goalsRate": {
            "title": "目标率",
            "tooltip": "表示对比按移动衡量服务提供商基准的安装数，目标活动所设定的自定义转换的比率。<br><b>目标率=目标\/安装*100%<\/b>"
        },
        "goalsRevenue": {
            "title": "目标收入",
            "tooltip": "表示目标活动所设定自定义转换的收益总额。此项目将计算从被绑定为「目标」的应用内事件而来的转化。"
        },
        "goalsRoasDays": {
            "title": "目标活动ROAS 1\/3\/7\/14\/30天",
            "tooltip": "安装1\/3\/7\/14\/30天内的广告支出回报率。 <br>该指标考虑了自定义转换下被绑定为「目标」的应用内事件所带来的收入。<br><b>总收入天数\/支出*100%<\/b>."
        },
        "goalValue": {
            "title": "目标值",
            "tooltip": "它应该是一个实际的或有代表性的值，将被用于计算收入。"
        },

        "impressions": {
            "title": "展示次数",
            "tooltip": "在App Store的搜索结果中作为广告出现的次数"
        },
        "installRate": {
            "title": "安装归因率",
            "tooltip": "第三方的安装数与Apple官方所衡量安装数的比率。<br> <b>安装数（第三方） \/ 安装数(新下载 + 重新下载) * 100<\/b>"
        },
        "installs": {
            "title": "安装（直通式）",
            "tooltip": "在 30 天归因窗口内点击广告的用户新下载和重新下载的总数。"
        },
        "keyword": {
            "title": "关键词",
            "tooltip": "该App所选择的关键词"
        },
        "negativeKeyword": {
            "title": "否定关键词",
            "tooltip": "否定关键词"
        },
        "keywordDisplayStatus": {
            "title": "关键词状态",
            "tooltip": "关键词状态"
        },
        "keywordMatchType": {
            "title": "@:headers.matchType.title"
        },
        "keywordStatus": {
            "title": "关键词状态",
            "tooltip": ""
        },
        "label": {
            "title": "标签",
            "tooltip": "由用户描述的标签"
        },
        "localSpend": {
            "title": "支出"
        },
        "locality": {
            "title": "地点",
            "tooltip": "地点"
        },
        "lowVolume": {
            "title": "数量较少",
            "tooltip": "“数量较少”意味着你请求的数据低于 Apple 的隐私阈值。搜索词必须至少达到每天 10 次展示，年龄、性别或位置报告至少需要每天 100 次展示，Apple Search Ads 才可以显示具体的数值。“数量较少”指标意味着累计数据低于以上标准。"
        },
        "lowVolumeKeyword": {
            "title": "Low Volume Term",
            "tooltip": "Low Volume Term"
        },
        "ltv": {
            "title": "LTV",
            "tooltip": "显示活动的生命周期价值"
        },
        "matchSource": {
            "title": "匹配来源",
            "tooltip": "匹配来源列会显示你的展示次数是源于搜索匹配还是已出价关键词。"
        },
        "matchType": {
            "title": "匹配类型"
        },
        "message": {
            "title": "信息"
        },
        "newDownloads": {
            "title": "新下载（自来水）",
            "tooltip": "点击您的广告但之前未下载过您的应用程序的用户的新下载。这些下载将在 30 天的归因窗口内计算。"
        },
        "notification": {
            "title": "通知"
        },
        "numberOfAds": {
            "title": "广告数量",
            "tooltip": "该产品页面使用的广告数量。"
        },
        "numberOfApps": {
            "title": "总App数",
            "tooltip": "每个关键词搜索排名的应用程序总数"
        },
        "numberOfGoal": {
            "title": "@:headers.numberOfGoals.title",
            "tooltip": "@:headers.numberOfGoals.tooltip"
        },
        "numberOfGoals": {
            "title": "目标设置",
            "tooltip": "用户在创建自定义转换时设置的一个自定义应用内活动。 <br>目标是你为自定义转换所设置的最重要的操作。"
        },
        "numberOfInstall": {
            "title": "@:headers.attrInstalls.title",
            "tooltip": "@:headers.attrInstalls.tooltip"
        },
        "orgName": {
            "title": "活动小组",
            "tooltip": "活动小组名称"
        },
        "organicRank": {
            "title": "自然排名",
            "tooltip": "该排名数据显示在Appstore搜索这个关键词时此应用程序的自然排名"
        },
        "otherEvents": {
            "title": "其他广告主",
            "tooltip": "其他目标类别所带来的事件数量。"
        },
        "others": {
            "title": "其他广告主",
            "tooltip": "选择此关键词投放的其他竞品App"
        },
        "overallRanking": {
            "title": "整体排名",
            "tooltip": "整体排名"
        },
        "policy": {
            "title": "政策",
            "tooltip": "请选择追踪目标活动的方式。所设定的值将被用于计算收入。"
        },
        "popularityRank": {
            "title": "流行度",
            "tooltip": "该分数由Apple SearchAds提供、以显示关键词在商店的受欢迎程度"
        },
        "productPageName": {
            "title": "产品页面名称",
            "tooltip": "产品页面名称"
        },
        "productPageId": {
            "title": "产品页面编号",
            "tooltip": "产品页面编号"
        },
        "purchases": {
            "title": "购买",
            "tooltip": "购买目标类别所带来的事件数量。"
        },
        "keywordId": {
            "title": "关键词ID",
            "tooltip": "关键词的Apple Search Ads识别子"
        },
        "reAttrType": {
            "title": "再归因设定",
            "tooltip": "此栏显示设定自动化规则时的再归因数据。"
        },
        "recommendedAction": {
            "title": "建议操作",
            "tooltip": "因为此关键词的转化率明显较低"
        },
        "recommendedActions": {
            "title": "操作",
            "tooltip": "你可以逐个应用或忽略建议。由于市场价格、用户偏好等其他因素会变化、所以使用建议并不能确保提升广告效果。"
        },
        "recommendedBid": {
            "title": "建议出价",
            "tooltip": "因为该关键词没有足够的展示次数来对标竞品"
        },
        "recommendedDailyBudget": {
            "title": "建议的每日预算",
            "tooltip": "之所以推荐，是因为这些活动的转化率明显较高，并多次达到每日预算的要求"
        },
        "recommendedLifetimeBudget": {
            "title": "建议的终身预算",
            "tooltip": "建议的终身预算"
        },
        "redownloads": {
            "title": "重新下载（点击通过）",
            "tooltip": "在 30 天归因窗口内点击过您的广告的用户重新下载的次数。当用户下载您的应用程序后将其删除，并在点击广告后在同一设备或不同设备上再次下载时，这些次数将被计算在内。"
        },
        "reportDate": {
            "title": "报告日期",
            "tooltip": "Apple Search Ads报告日期"
        },
        "returnOnAdvertisingSpend": {
            "title": "ROAS",
            "tooltip": "<b>ROAS（广告支出回报率）：<\/b> <br>表示广告支出所带来的回报。 <br>ROAS是指你花在广告的每一个单位所获得的收入份额。 <br>计算公式为： <br><b>ROAS=收入\/支出*100%<\/b>."
        },
        "revenue": {
            "title": "收入",
            "tooltip": "你的广告系列为应用程序所带来的总收入"
        },
        "revenuePerConversion": {
            "title": "每次转化的收入",
            "tooltip": "指针对所有安装数、每1个转化所获得收益"
        },
        "revenuePerGoal": {
            "title": "每个目标的收入",
            "tooltip": "表示你在每个成功目标所产生的平均收入。<br>用成功目标总数除以目标收入数字计算而来。"
        },
        "roas1": {
            "title": "ROAS 1",
            "tooltip": "<b>ROAS（广告支出回报率）：<\/b> <br>表示1天内广告支出所带来的回报。 <br>ROAS是指你花在广告的每一个单位所获得的收入份额。<br>计算公式为： <br><b>ROAS=收入1\/支出*100%<\/b>"
        },
        "roas14": {
            "title": "ROAS 14",
            "tooltip": "<b>ROAS（广告支出回报率）：<\/b> <br>表示14天内广告支出所带来的回报。 <br>ROAS是指你花在广告的每一个单位所获得的收入份额。<br>计算公式为： <br><b>ROAS=收入14\/支出*100%<\/b>"
        },
        "roas3": {
            "title": "ROAS 3",
            "tooltip": "<b>ROAS（广告支出回报率）：<\/b> <br>表示3天内广告支出所带来的回报。 <br>ROAS是指你花在广告的每一个单位所获得的收入份额。<br>计算公式为： <br><b>ROAS=收入3\/支出*100%<\/b>"
        },
        "roas30": {
            "title": "ROAS 30",
            "tooltip": "<b>ROAS（广告支出回报率）：<\/b> <br>表示30天内广告支出所带来的回报。 <br>ROAS是指你花在广告的每一个单位所获得的收入份额。<br>计算公式为： <br><b>ROAS=收入30\/支出*100%<\/b>"
        },
        "roas7": {
            "title": "ROAS 7",
            "tooltip": "<b>ROAS（广告支出回报率）：<\/b> <br>表示7天内广告支出所带来的回报。 <br>ROAS是指你花在广告的每一个单位所获得的收入份额。<br>计算公式为： <br><b>ROAS=收入7\/支出*100%<\/b>"
        },
        "roasDays": {
            "title": "ROAS 1\/3\/7\/14\/30日",
            "tooltip": "<b>ROAS（广告支出回报率）：<\/b> <br>表示1\/3\/7\/14\/30天内广告支出所带来的回报。 <br>ROAS是指你花在广告的每一个单位所获得的收入份额。<br>计算公式为： <br><b>ROAS=收入\/支出*100%<\/b>"
        },
        "ruleActions": {
            "title": "操作",
            "tooltip": "当条件满足时会发生的行动"
        },
        "ruleChanges": {
            "title": "变化数",
            "tooltip": "表示发生变化的数量。\n<br><br>\n<strong>无变化<\/strong>-规则运行但未带来变动<br><br>\n<strong>N件 : 成功<\/strong>-被改变的件数<br><br>\n<strong>N件 : 警告<\/strong>-被规则改变、同时警告的数量，如预算上限等<br><br>\n<strong>N件 : 失败<\/strong>-符合规则但由于非技术性错误（如超过每日预算上限等）而未被改变的对象数量。"
        },
        "ruleDateChecked": {
            "title": "检查时间",
            "tooltip": "规则说明中描述的操作时间"
        },
        "ruleDescription": {
            "title": "规则说明",
            "tooltip": "按照规则运行时操作、条件和对象的展示情况。<br><br>点击规则行来过滤与之相关的所有规则。"
        },
        "ruleLogState": {
            "title": "状态",
            "tooltip": "状态显示操作的实施是否有误。<br>\n<strong>成功<\/strong>-操作成功没有失误。<br>\n<strong>Apple：注意<\/strong>-在Apple限制内完成操作。<br>\n<strong>用户：注意<\/strong>-在用户限制内完成操作。<br>\n<strong>Apple：警告<\/strong>-由于Apple限制、操作未完成。<br>\n<strong>用户：警告<\/strong>-由于用户限制、操作未完成。<br>\n<strong>失败<\/strong>-由于技术问题导致操作失败"
        },
        "ruleName": {
            "title": "规则名称",
            "tooltip": "帮助你识别自动化规则的名称"
        },
        "ruleRunState": {
            "title": "状态",
            "tooltip": "状态显示规则运行是否有误。\n<br><br>\n<strong>运行成功<\/strong>-成功运行没有失误。<br><br>\n<strong>运行警告<\/strong>-规则在预先定义的限制下运行，如预算上限等。<br><br>\n<strong>运行失败<\/strong>-由于非技术性错误（如超过每日预算上限等）而运行失败。<br><br>\n<strong>操作错误<\/strong>-由于技术性错误而运行失败。"
        },
        "ruleType": {
            "title": "规则类型",
            "tooltip": "客户合作伙伴自动化 \"规则类型仅在客户合作伙伴冒充账户时可见"
        },
        "rulesFor": {
            "title": "创建规则用于",
            "tooltip": "你可以为不同的广告系列、广告组或关键词创建自动化规则。"
        },
        "searchAdsVisibilityScore": {
            "title": "SA 可见度得分",
            "tooltip": "该指标表示应用程序在Apple商店SearchAds位置上的可见度。分数在0-100之间。"
        },
        "searchMatch": {
            "title": "搜索匹配",
            "tooltip": "<b>搜索匹配<\/b> 使用多种资源<br>将你的广告与App Store上的相关搜索进行匹配，<br>其中包括你的App Store列表中的元数据<br>同一类型中关于类似App的信息及其他搜索数据。"
        },
        "searchScore": {
            "title": "流行度",
            "tooltip": "关键词的人气指标。得分越高代表被搜索的量越大。"
        },
        "searchTerm": {
            "title": "搜索词",
            "tooltip": "搜索词是人们用来寻找特定类型的应用程序时所用到的关键词和短语。"
        },
        "searchTermKeyword": {
            "title": "关键词",
            "tooltip": "搜索词文本"
        },
        "searchTermKeywordId": {
            "title": "关键词ID",
            "tooltip": "苹果搜索广告关键字标识符"
        },
        "searchTermSource": {
            "title": "搜索词来源",
            "tooltip": "搜索词来源"
        },
        "searchTermText": {
            "title": "搜索词文本",
            "tooltip": "搜索词文本"
        },
        "source": {
            "title": "资料来源",
            "tooltip": "此列显示关键词出价变化的来源。有四种来源类型：<br><ul> <li> <b> 仪表板：<\/b><\/li><li> <b> 外部：<\/b><\/li><li> <b> 自动化：<\/b> 使用自动化规则更新关键字出价。<\/li><li> <b> 智能出价：<\/b> 使用智能竞价更新关键字出价<\/li><\/ul>"
        },
        "spend": {
            "title": "支出",
            "tooltip": "截至目前，在该广告系列所花费的总金额。"
        },
        "startDate": {
            "title": "开始日期",
            "tooltip": "开始日期"
        },
        "stateReason": {
            "title": "暂停 原因",
            "tooltip": "暂停自动化的原因"
        },
        "status": {
            "title": "状态",
            "tooltip": "状态"
        },
        "storefront": {
            "title": "国家或地区",
            "tooltip": "显示该广告系列在那些国家或地区投放"
        },
        "strategies": {
            "title": "战略",
            "tooltip": "此栏显示当前日期已分配的自动操作。"
        },
        "strategiesWithSb": {
            "title": "战略",
            "tooltip": "本栏显示当前日期已分配的智能竞价和自动操作。"
        },
        "subscriptions": {
            "title": "订阅",
            "tooltip": "订阅目标类别所带来的事件数量"
        },
        "taps": {
            "title": "点击次数",
            "tooltip": "你的广告被点击的次数"
        },
        "todaysRank": {
            "title": "排行（今天）",
            "tooltip": "所选应用程序在该关键词的自然排行"
        },
        "totalBudget": {
            "title": "终身预算",
            "tooltip": "分配给活动的终生预算。"
        },
        "totalPaidKeywords": {
            "title": "付费关键词总数",
            "tooltip": "应用程序在投放广告的关键词总数"
        },
        "trials": {
            "title": "试用",
            "tooltip": "试用目标类别所带来的事件数量"
        },
        "ttr": {
            "title": "TTR",
            "tooltip": "<b>点击率（TTR）<\/b> <br>由用户点击广告的次数除以广告获得的总展示次数计算得出。"
        },
        "yesterdaysRank": {
            "title": "排行（昨天）",
            "tooltip": "所选应用程序在该关键词的排行"
        },
        "campaignStartTime": {
            "title": "",
            "tooltip": ""
        }
    },
    "conditions": {
        "SPEND": "@:headers.spend.title",
        "CPA": "@:headers.averageCPA.title",
        "TOTAL_CPA": "@:headers.totalCpa.title",
        "CPT": "@:headers.averageCPT.title",
        "TTR": "@:headers.ttr.title",
        "CR": "@:headers.conversionRate.title",
        "TOTAL_CR": "@:headers.totalCr.title",
        "INSTALLS": "@:headers.installs.title",
        "VIEW_INSTALLS": "@:headers.viewInstalls.title",
        "TOTAL_INSTALLS": "@:headers.totalInstalls.title",
        "TAPS": "@:headers.taps.title",
        "IMPRESSIONS": "@:headers.impressions.title",
        "TOTAL_BUDGET": "@:headers.totalBudget.title",
        "DAILY_BUDGET": "@:headers.dailyBudget.title",
        "NEW_DOWNLOADS": "@:headers.newDownloads.title",
        "VIEW_NEW_DOWNLOADS": "@:headers.viewNewDownloads.title",
        "TOTAL_NEW_DOWNLOADS": "@:headers.totalNewDownloads.title",
        "REDOWNLOADS": "@:headers.redownloads.title",
        "VIEW_REDOWNLOADS": "@:headers.viewRedownloads.title",
        "TOTAL_REDOWNLOADS": "@:headers.totalRedownloads.title",
        "ATTR_INSTALLS": "@:headers.attrInstalls.title",
        "GOALS": "@:headers.numberOfGoals.title",
        "GOALS_REVENUE": "@:headers.goalsRevenue.title",
        "INSTALL_RATE": "@:headers.installRate.title",
        "CPI": "@:headers.averageCPI.title",
        "COST_PER_GOAL": "@:headers.costPerGoal.title",
        "GOALS_RATE": "@:headers.goalsRate.title",
        "REVENUE_PER_GOAL": "@:headers.revenuePerGoal.title",
        "GOALS_ROAS": "@:headers.goalsROAS.title",
        "ARPU": "@:headers.averageRevenuePerUser.title",
        "GOALS_REVENUE_1": "@:headers.goalRevenue1.title",
        "GOALS_REVENUE_3": "@:headers.goalRevenue3.title",
        "GOALS_REVENUE_7": "@:headers.goalRevenue7.title",
        "GOALS_REVENUE_14": "@:headers.goalRevenue14.title",
        "GOALS_REVENUE_30": "@:headers.goalRevenue30.title",
        "GOALS_REVENUE_60": "@:headers.goalRevenue60.title",
        "GOALS_COUNT_1": "@:headers.goalCount1.title",
        "GOALS_COUNT_3": "@:headers.goalCount3.title",
        "GOALS_COUNT_7": "@:headers.goalCount7.title",
        "GOALS_COUNT_14": "@:headers.goalCount14.title",
        "GOALS_COUNT_30": "@:headers.goalCount30.title",
        "GOALS_COUNT_60": "@:headers.goalCount60.title",
        "GOALS_ROAS_1": "@:headers.goalRoas1.title",
        "GOALS_ROAS_3": "@:headers.goalRoas3.title",
        "GOALS_ROAS_7": "@:headers.goalRoas7.title",
        "GOALS_ROAS_14": "@:headers.goalRoas14.title",
        "GOALS_ROAS_30": "@:headers.goalRoas30.title",
        "GOALS_ROAS_60": "@:headers.goalRoas60.title",
        "CUSTOM_COLUMN": "@:headers.CUSTOM_COLUMN.title",
        "LABEL": "@:headers.label.title",
        "APPLE_SHARE_OF_VOICE_DAILY": "@:headers.appleShareOfVoiceDaily.title",
        "APPLE_SHARE_OF_VOICE_WEEKLY": "@:headers.appleShareOfVoiceWeekly.title",
        "CPA_GOAL": "@:headers.cpaGoal.title",
        "LTV": "@:headers.ltv.title",
        "BID_AMOUNT": "@:headers.bidAmount.title",
        "DEFAULT_BID_AMOUNT": "@:headers.defaultBidAmount.title",
        "ORGANIC_RANK": "@:headers.organicRank.title",
        "STOREFRONT": "@:headers.storefront.title",
        "CAMPAIGN_NAME": "@:headers.campaign.title",
        "CAMPAIGN_GROUP_NAME": "@:headers.orgName.title",
        "AD_GROUP_NAME": "@:headers.adGroupName.title",
        "SEARCH_MATCH": "@:headers.searchMatch.title",
        "DEVICE": "@:headers.deviceClass.title",
        "GENDER": "@:headers.gender.title",
        "CUSTOMER_TYPE": "@:headers.customerType.title",
        "LOCATION": "@:headers.locality.title",
        "AGE_RANGE": "@:headers.age.title",
        "KEYWORD": "@:headers.keyword.title",
        "MATCH_TYPE": "@:headers.matchType.title",
        "SUGGESTED_BID_AMOUNT": "@:headers.suggestedBidAmount.title",
        "BID_STRENGTH": "@:headers.bidStrength.title",
        "ADDED_AS_KEYWORD": "@:headers.addedAsKeyword.title",
        "SEARCH_TERM_SOURCE": "@:headers.searchTermSource.title",
        "SEARCH_TERM_TEXT": "@:headers.searchTermText.title",
        "LOW_VOLUME_TERM": "@:headers.lowVolumeKeyword.title",
        "POPULARITY": "",
        "LAST_BUDGET_UPDATE": "@:headers.lastBudgetUpdate.title",
        "LAST_BID_UPDATE": "@:headers.lastBidUpdate.title"
    }
}
